<template>
<Toast/>
<!-- <Navbar/> -->
<SideBar/>
 <div class="card main-content">
    <!-- <div class="surface-ground"> -->
    <div class="card flex  justify-content-center">
        <!-- <Card style="width: 100em; height:100%;"> -->
        <Card class="main-card">
            <!-- <template #title> Add new ROC </template> -->
            <template #subtitle><div class="flex justify-content-between align-items-center" > <h2>New ROC</h2>  <Button class="p-button-sm" size="small" label="Save" icon="pi pi-save" severity="success" @click="handleSave"/></div><Divider/>  </template>
            
            <template #content>
              <div class="col-12">
                <div class="card flex flex-wrap gap-3 p-fluid">
                    <div class="flex-auto">
                        <label for="tenderName" class="font-bold block mb-2"> Tender </label>
                        <InputText v-model="tenderName" />
                    </div>
                </div>
              </div>
                
                <div class="col-12">
                  <div class="card flex flex-wrap p-fluid ">
                    <div class="col-4">
                      <div class="flex-auto">
                          <label for="tenderDate" class="font-bold block mb-2"> Tender Date </label>
                          <Calendar v-model="tenderDate" showIcon dateFormat="dd/mm/yy" inputId="buttondisplay" />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="flex-auto">
                        <label for="department" class="font-bold block mb-2">Department</label>
                        <Dropdown size="small" v-model="selectedDept" editable :options="departments" optionValue="id" optionLabel="name" placeholder="Select Department" />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="flex-auto">
                            <label for="rocNo" class="font-bold block mb-2"> ROC No. </label>
                            <InputText v-model="rocNo" />
                      </div>
                    </div>
                 </div>
                </div>
                
                  
                  <div class="card flex flex-wrap p-fluid">
                    <div class="col-4">
                      <div class="flex-auto">
                          <label for="rocDate" class="font-bold block mb-2"> ROC Date </label>
                          <Calendar v-model="rocDate" showIcon dateFormat="dd/mm/yy" inputId="buttondisplay" />
                      </div>
                    </div>
                    
                    <div class="col-4">
                      <div class="flex-auto">
                          <label for="validity" class="font-bold block mb-2"> Validity (Years) </label>
                          <InputNumber v-model="validity" showIcon  inputId="integeronly" />
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="flex-auto">
                          <!-- <label for="rocDocument" class="font-bold block mb-2"> Upload ROC</label>
                          <FileUpload mode="basic" size="small" name="rocfile[]" :url="url" accept=".pdf"  :maxFileSize="1000000" @before-upload="handleFileData" customUpload="true" @uploader="handleUpload" /> -->
    
                      </div>
                    </div>
                  </div>

                  <Divider/>
                  <h4>Item details:</h4>
                  
                  <!-- individual item component -->
                  <ul>
                      <RocItem v-for="(item, index) in items" :key="item.itemId" :index="index" :itemId="item.itemId"  :categories="categories.map(a =>a)" :manufacturers="manufacturers.map(b =>b)"  @delete="items.splice(index, 1)" @bind-item="bindData"/>

                  </ul>
                  <!-- Add items btn -->
                  <div class="flex justify-content-end align-items-center" > <Button label="Add item" size="small" icon="pi pi-plus" @click="addItem" /> </div>
            </template>
            <template #footer>
                
            </template>
        </Card>
    </div>
    <!-- </div> -->
 </div>
</template>

<script>
import InputText from 'primevue/inputtext'
// import Navbar from '../header/headerNavbar.vue' 
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import Dropdown from 'primevue/dropdown'
// import FileUpload from 'primevue/fileupload'
import InputNumber from 'primevue/inputnumber'
import Toast from 'primevue/toast'
import Button from 'primevue/button'
import Divider from 'primevue/divider'
import RocItem from './rocItem.vue'
import { getCategories, getDepartments, getManufacturers, createRoc } from '../../services/apiService'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
  components:{
    // Navbar,
    SideBar,  
    Card, 
    InputText, 
    Calendar, 
    Dropdown, 
    // FileUpload, 
    InputNumber, 
    Toast, 
    Button, 
    Divider, 
    RocItem, 
  },
  data(){
    return{
      tenderName: '',
      tenderDate: '',
      rocNo:'',
      rocDate:'',
      validity: 0,
      selectedDept: null,
      rocDocument:[],
      departments: null,
      categories:null,
      manufacturers: null,
      items: [],
      itemId: 0,
      itemName: null,
      selectedCategory: null,
      itemRate: 0,
      itemPacking: null,
      itemManufacturer: null
    }
  },
  methods:{
    getDepts(){
      getDepartments().then((res)=> 
            {
                this.departments = res.data
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
    },
    getCategory(){
      getCategories().then((res)=> 
            {
                this.categories = res.data
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
    },
    getManufacturer(){
      getManufacturers().then((res)=> 
            {
                this.manufacturers = res.data
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
    },
    addItem(){
      this.items.push({
        itemId: this.itemId++,
        itemName: this.itemName,
        itemRate: this.itemRate,
        selectedCategory: this.selectedCategory,
        itemPacking: this.itemPacking,
        itemManufacturer: this.itemManufacturer
      });
    },
    bindData(itemId, itemData){
      const item = this.items.find((e) => e.itemId == itemId);
      item.itemName = itemData.itemName;
      item.itemRate = itemData.itemRate;
      item.itemPacking = itemData.itemPacking;
      item.itemManufacturer = itemData.itemManufacturer;
      item.selectedCategory = itemData.selectedCategory;
    },
    handleUpload(files) {    
      console.log(files); 
            // this.$toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
        },
    handleFileData(files){
     console.log(files);
    },
    showSuccessToast(data){
        this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
    },   
    showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
    },
    
    handleSave(){

      if(this.tenderName=='' || this.selectedDept=='' || this.rocNo==''){
        this.showErrorToast({'message': 'All fields must be filled!'})
        return
      }
      if(this.validity==0){
        this.showErrorToast({'message': 'Validity cannot be "0"!'})
        return
      }

      const data ={
        tenderName: this.tenderName,
        tenderDate: this.tenderDate.getDate()+'-'+(this.tenderDate.getMonth()+1)+'-'+this.tenderDate.getFullYear(),
        departmentId: this.selectedDept,
        contractNo: this.rocNo,
        contractDate: this.rocDate.getDate()+'-'+(this.rocDate.getMonth()+1)+'-'+this.rocDate.getFullYear(),
        period: this.validity,
        rocDetails: this.items,
      }
  
      createRoc(data).then((res)=> 
            {
              this.showSuccessToast(res.data);
              this.$router.push('roc-list');
            }
          ).catch((error)=>this.showErrorToast(error.response.data));
    },
    
  },
  mounted(){
      this.itemId = 0
      this.getDepts()
      this.getCategory()
      this.getManufacturer()
    }

  
}
</script>

<style scoped>

.main-card{
  width: calc(100% - 100px);
  min-width: calc(100% - 40px);
  margin-top: 20px;
}

</style>