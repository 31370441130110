<template>
  <div class="main-content">

    <div class="progress-spinner" v-if="spinnerLoadingStatus">
      <ProgressSpinner></ProgressSpinner>
    </div>

    <div class="flex justify-content-between flex-wrap my-2 mx-3">
        <div class="flex align-items-center justify-content-center ">
          <label class="text-xl font-medium text-800">Notifications</label> 
        </div>
        <div class="flex align-items-center justify-content-center">
          <!-- <Button label="Mark all as read" severity="success" raised text></Button> -->
        </div>
    </div>
    <div class="mx-3">
      <TabView>
          <TabPanel header="All">
            <div v-if="notificationsArr.length>0" class="flex justify-content-between flex-wrap my-2 mx-3">
                <div class="flex align-items-center justify-content-center ">
                  <!-- <Button v-tooltip.top="'Delete all notifications'" label="Delete All" size="small" severity="danger" raised></Button> -->
                </div>
                <div  class="flex align-items-center justify-content-center">
                  <label class="text-sm"></label><span class="text-red-500 mr-1">*</span>Total {{notificationsArr.length}} notifications loaded
                </div>
            </div>
            <ScrollPanel style="width: 100%; height: 75vh" :pt="{             
              wrapper:{
                style:{
                  border:0
                }
              }
            }">
              
              <div v-for="(item, index) in notificationsArr" :key="index" >
                  <div v-if="updateNotificationDate(item.createdOn)" class="flex border-300 border-solid border-none border-round-lg bg-green-50 my-1 mx-1">
                    <div class="flex-initial flex align-items-center justify-content-center border-round-lg bg-green-100 w-1 w-3rem h-3rem ml-3 my-2">
                      <i :class="item.icon" style="font-size: 1.5rem"></i>
                    </div>
                    <div class=" flex w-9"> 
                        <div class="field ml-4 my-1">
                            <label class="text-base font-semibold">{{item.title}} {{item.location ? 'at '+item.location : null}}</label>
                            <div class="text-sm m-0">{{item.message}}</div>
                        </div>             
                    </div>
                    <div class="flex-initial flex align-items-center justify-content-center w-1">
                      <Tag v-if="updateNotificationDate(item.createdOn)" value="New" severity="success"></Tag>
                    </div>
                    <!-- <div class="flex-initial flex align-items-center justify-content-center w-2">
                      <Button label="Mark as read"  raised size="small"></Button>
                    </div> -->
                    <div class="flex-initial flex align-items-center justify-content-center w-1">
                      <!-- <Button  icon="pi pi-trash" severity="danger" text raised size="small"></Button> -->
                    </div>
                  </div>

                  <div v-if="!updateNotificationDate(item.createdOn)" class="flex border-round border-200 border-solid border-1 border-round my-1 mx-1 ">
                    <div class="flex-initial flex align-items-center justify-content-center border-round-lg w-1 bg-green-100 w-3rem h-3rem ml-3 my-2">
                      <i :class="item.icon" style="font-size: 1.5rem"></i>
                    </div>
                    <div class=" flex w-9"> 
                        <div class="field ml-4 my-1">
                            <label class="text-base font-semibold">{{item.title}} {{item.location ? 'at '+item.location : null}}</label>
                            <div class="text-sm m-0">{{item.message}}</div>
                        </div>             
                    </div>
                    <div class="flex-initial flex align-items-center justify-content-center w-1">
                      
                      <label v-if="!updateNotificationDate(item.createdOn)">{{item.createdOn}}</label>
                    </div>
                    <!-- <div class="flex-initial flex align-items-center justify-content-center w-2">
                      <Button label="Mark as read"  raised size="small"></Button>
                    </div> -->
                    <div class="flex-initial flex align-items-center justify-content-center w-1">
                      <!-- <Button  icon="pi pi-trash" severity="danger" text raised size="small"></Button> -->
                    </div>
                  </div>
              </div>

              <div v-if="notificationsArr.length==0" class="flex justify-content-center flex-wrap">
                  <div class="flex align-items-center justify-content-center">No data</div>
              </div>
            </ScrollPanel>
              <!-- <Panel> -->
              <!-- </Panel> -->
          </TabPanel>
          <TabPanel header="New">
            <div v-if="newNotifications.length>0" class="flex justify-content-between flex-wrap my-2 mx-3">
                <div class="flex align-items-center justify-content-center ">
                  <!-- <Button v-tooltip.top="'Delete all notifications'" label="Delete All" size="small" severity="danger" raised></Button> -->
                </div>
                <div  class="flex align-items-center justify-content-center">
                  <label class="text-sm"></label><span class="text-red-500 mr-1">*</span>Total {{newNotifications.length}} notifications for today ({{presentDate}})
                </div>
            </div>
              <ScrollPanel style="width: 100%; height: 80vh" :pt="{             
              wrapper:{
                style:{
                  border:0
                }
              }
            }">
              <div v-if="newNotifications.length>0">
                  <div v-for="(item, index) in newNotifications" :key="index" class="flex border-round border-300 border-solid border-none border-round bg-green-50 my-1 mx-1">
                    <div class="flex-initial flex align-items-center justify-content-center border-round-lg bg-green-100 w-3rem h-3rem ml-3 my-2">
                      <i :class="item.icon" style="font-size: 1.5rem"></i>
                    </div>
                    <div class=" flex w-9"> 
                        <div class="field ml-4 my-1">
                            <label class="text-base font-semibold">{{item.title}} {{item.location ? 'at '+item.location : null}}</label>
                            <div class="text-sm m-0">{{item.message}}</div>
                        </div>             
                    </div>
                    <div class="flex-initial flex align-items-center justify-content-center w-1">
                      <Tag v-if="updateNotificationDate(item.createdOn)" value="New" severity="success"></Tag>
                      <!-- <label v-if="updateNotificationDate(item.createdOn)" class="border-round bg-green-400 text-white px-4 py-2">New</label> -->
                      <label v-if="!updateNotificationDate(item.createdOn)">{{item.createdOn}}</label>
                    </div>
                    <!-- <div class="flex-initial flex align-items-center justify-content-center w-2">
                      <Button label="Mark as read"  raised size="small"></Button>
                    </div> -->
                    <div class="flex-initial flex align-items-center justify-content-center w-1">
                      <!-- <Button icon="pi pi-trash" severity="danger" text raised size="small"></Button> -->
                    </div>
                  </div>
              </div>
              
                <div v-if="newNotifications.length==0" class="flex justify-content-center flex-wrap">
                    <div class="flex align-items-center justify-content-center">No data</div>
                </div>

              </ScrollPanel>
          </TabPanel>
          <!-- <TabPanel header="Unread">
              <ScrollPanel style="width: 100%; height: 80vh" :pt="{             
              wrapper:{
                style:{
                  border:0
                }
              }
            }">
              <div v-for="(item, index) in unreadNotifications" :key="index" >
                <div v-if="updateNotificationDate(item.createdOn)" class="flex border-round border-300 border-solid border-none border-round bg-green-50 my-1 mx-1">
                  <div class="flex-initial flex align-items-center justify-content-center border-round-lg bg-green-100 w-3rem h-3rem ml-3 my-2">
                    <i :class="item.icon" style="font-size: 1.5rem"></i>
                  </div>
                  <div class=" flex w-9"> 
                      <div class="field ml-4 my-1">
                          <label class="text-base font-semibold">{{item.title}} {{item.location ? 'at '+item.location : null}}</label>
                          <div class="text-sm m-0">{{item.message}}</div>
                      </div>             
                  </div>
                  <div class="flex-initial flex align-items-center justify-content-center w-1">
                    <Tag v-if="updateNotificationDate(item.createdOn)" value="New" severity="success"></Tag>
                    
                    <label v-if="!updateNotificationDate(item.createdOn)">{{item.createdOn}}</label>
                  </div>
                  <div class="flex-initial flex align-items-center justify-content-center w-1">
                    <Button icon="pi pi-trash" severity="danger" text raised size="small"></Button>
                  </div>
                </div>
                <div v-if="!updateNotificationDate(item.createdOn)" class="flex border-round border-200 border-solid border-1 border-round my-1 mx-1">
                    <div class="flex-initial flex align-items-center justify-content-center border-round-lg bg-green-100 w-3rem h-3rem ml-3 my-2">
                      <i :class="item.icon" style="font-size: 1.5rem"></i>
                    </div>
                    <div class=" flex w-7"> 
                        <div class="field ml-4 my-1">
                            <label class="text-sm font-semibold">{{item.title}} {{item.location ? 'at '+item.location : null}}</label>
                            <div class="text-sm m-0">{{item.message}}</div>
                        </div>             
                    </div>
                    <div class="flex-initial flex align-items-center justify-content-center w-1">
                      
                      <label v-if="!updateNotificationDate(item.createdOn)">{{item.createdOn}}</label>
                    </div>
                    <div class="flex-initial flex align-items-center justify-content-center w-2">
                      <Button label="Mark as read" raised size="small"></Button>
                    </div>
                    <div class="flex-initial flex align-items-center justify-content-center w-1">
                      <Button  icon="pi pi-trash" severity="danger" text raised size="small"></Button>
                    </div>
                  </div>
              </div>

              <div v-if="unreadNotifications==null" class="flex justify-content-center flex-wrap">
                  <div class="flex align-items-center justify-content-center">No data</div>
              </div>

              </ScrollPanel>
          </TabPanel> -->
      </TabView>
    </div>
  </div>
</template>

<script>
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
// import Button from 'primevue/button';
// import Panel from 'primevue/panel';
import { markAllReadCmcNotifications, notifications } from '@/services/apiService';
import ScrollPanel from 'primevue/scrollpanel';
import ProgressSpinner from 'primevue/progressspinner';
import Tag from 'primevue/tag';
import { useNotificationStore } from '@/stores/notificationStore';

export default {
  
  components:{
    TabView,
    TabPanel,
    // Button,
    ScrollPanel,
    // Panel,
    ProgressSpinner,
    Tag,
  },

  data(){
    return{
      notificationsArr:[],
      newNotifications:[],
      unreadNotifications:null,
      spinnerLoadingStatus:false,
      presentDate:null,
    }
  },

  methods:{
    fetchNotifications(){
      this.spinnerLoadingStatus = true;
      notifications().then((res)=>{
        this.notificationsArr = res.data.data;  
        this.notificationsArr.forEach(e => {
          if(this.updateNotificationDate(e.createdOn)){
            this.newNotifications.push(e);
          }
        });
        // this.unreadNotifications = this.notificationsArr.filter(this.filterUnreadNotification);
        this.spinnerLoadingStatus = false;
      }).catch((error)=>{
        this.showErrorToast(error.response.data);
        this.spinnerLoadingStatus = false;
      })
    },
    filterUnreadNotification(notification){      
      return notification.readOn==null;
    },
    updateNotificationDate(date){
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;

      let yyyy = today.getFullYear();

      if (dd < 10) {
          dd = '0' + dd;
      }
      if (mm < 10) {
          mm = '0' + mm;
      }
      today = dd + '-' + mm + '-' + yyyy;
      this.presentDate = today;
      if(today==date){
        return true
      }
      return false;      
    },
    markNotificationsAsRead(){
      markAllReadCmcNotifications().then().catch((error)=>{
        this.showErrorToast(error.response.data);
      }) 
    },
    showErrorToast(error){
        this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
    },
    showSuccessToast(data){
        this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 3000 });
    },
  },

  mounted(){
    this.fetchNotifications();  
    const store = useNotificationStore()
    store.$reset()
    this.markNotificationsAsRead();
  },


}
</script>

<style scoped>

</style>