<template>
<Toast/>
<!-- <Navbar/> -->
<SideBar/>
 
<div class="card main-content">
    <div class="progress-spinner" v-if="loadingStatus">
        <ProgressSpinner></ProgressSpinner>
    </div>
    <div class="surface-ground">
        <div class="grid">
        
            <div class="col-7">
                <Card style="height:100%;">
                     <template #header >
                        <div class="flex flex-wrap align-items-center justify-content-between">
                            <span class="px-4 pt-4">
                                <h3>OEM PO Details <Tag v-if="storeStatus" value="PO Draft" severity="warning" class="mx-3"/> </h3> 
                                
                            </span>
                            <span class="px-4 pt-4">
                                <Button v-if="!storeStatus" class="p-button-sm" size="small" label="Generate PO Draft" icon="pi pi-save" severity="success" @click="handleSave"/>
                                <Button v-if="storeStatus" class="p-button-sm" size="small" label="Update OEM PO" icon="pi pi-save" severity="success" @click="handleSave"/>
                            </span>
                            <Divider/>
                        </div>
                       
                              
                            <div class="formgrid grid">
                                <div class="field col px-4">
                                    <label class="font-bold block" for="poValue">Category:</label>
                                    <TreeSelect class="surface-overlay w-full" :options="categories" v-model="oemPoCategory" @change="setTemplate"/>
                                </div>
                                <div class="field col px-4">
                                    <label class="font-bold block" for="poNo">Order No.:</label>
                                    <InputText v-model="oemPoNo" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full" readonly/>
                                </div>
                                
                            </div>

                            <!-- 2nd row of input -->
                            <div class="formgrid grid">
                                
                                <div class="field col px-4">
                                    <label class="font-bold block" for="poValue">To:</label>
                                    <Dropdown class="surface-overlay w-full" :options="filteredOems" filter optionValue="id" optionLabel="name" v-model="oemManufacturer" @change="enableElement(); getOrderNo()" :disabled="storeStatus"/>
                                </div>
                                <div class="field col">
                                    <label class="font-bold block" for="poDate">Date:</label>
                                    <Calendar showIcon v-model="oemPoDate" dateFormat="dd/mm/yy" :readonly="poDateElement" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full pr-3"/>
                                </div>
                                
                            </div>

                            <div class="formgrid grid my-2">
                                <div class="field col px-4">
                                    <label class="mx-2 my-1"><strong> Deliver to Store: </strong></label>
                                    <Checkbox v-model="storeDelivery" :binary="true" @change="checkDeliveryLocation"/>
                                </div>
                                <div class="field col mr-3">
                                    <label v-if="storeDelivery">
                                        <strong>
                                            Available Stores:
                                        </strong>
                                    </label>
                                    <Dropdown v-if="storeDelivery" class="surface-overlay w-full " :options="stores" optionValue="uuid" optionLabel="location" v-model="selectedStore"/>
                                </div>
                            </div>

                        <!-- OEM PO Items -->
                    <div >

                        <Divider/>

                        <div v-if="poItems.length>0" class="flex flex-wrap align-content-center justify-content-between">
                           <span><label class="font-bold block px-3" for="itemDetails">Item Details:</label></span> 
                           <span>
                                <div class="flex align-items-center mx-1">
                                    <h4 class="mx-2">Additional Item Info: </h4>
                                        <Checkbox v-model="addlInfoChoice" :binary="true" @change="showAddltInfoDialog" />
                                        <label class="ml-2 mr-3">Yes</label>
                                    </div> 
                           </span>
                        </div>


                        <div v-if="poItems.length>0" class="px-3 py-1">
                            <PoItem v-for="(item, index) in poItems" :key="item.id" :index="index" :id="item.id" :item="item" :name="item.name" :rate="item.rate" :packing="item.packing" :rates="rateList" :newColumnStatus="newColumnStatus" :columnHead="newColumnHead" @bind-item="bindData"/>
                        </div>

                        <div class="flex justify-content-between flex-wrap pt-5 px-4">
                            <span>
                                <Button v-if="storeStatus" class="px-3 py-2" severity="success" label="PO Items" icon="pi pi-window-maximize" size="small"  @click="showPoItemsDialog"/>
                            </span>
                            <span v-if="poItems.length>0">
                                <label class="font-bold block" for="totalPoValue">Total Value: </label>
                                            <InputNumber v-model="poValue" mode="currency" currency="INR" currencyDisplay="code" locale="en-IN" readonly/>          
                            </span>
                            
                        </div>
                    </div>


                            <div class="formgrid grid">
                                <div class="field col px-4 py-1">   
                                    <label class="font-bold block" for="paymentTerms">Terms & Conditions:</label>                             
                                    <Textarea class="surface-overlay w-full" v-model="paymentTerms" rows="5" :readonly="storeStatus"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col px-4 py-1">
                                    <label class="font-bold block" for="paymentTerms">Penalty Clause:</label>  
                                    <Textarea class="surface-overlay w-full" v-model="penaltyClause" rows="5"/>
                                </div>
                            </div>

                            <Dialog :visible="addInfoDialogVisibility" modal :closable="false" header="Additional Item detail" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                
                                <div class="formgrid grid">
                                    <div class="field col">
                                        <h3 >Head:</h3>
                                        <InputText  v-model="newColumnHead" class="w-full" placeholder="Enter column head"/>
                                    </div>
                                </div>

                                <template #footer>
                                    <div class="flex flex-row-reverse flex-wrap">
                                        <div class="flex align-items-center justify-content-center ">
                                            <Button class="flex align-items-center justify-content-center " size="small" label="Cancel" severity="danger" raised @click="hideAddltInfoDialog"></Button>
                                        </div>
                                        <div class="flex align-items-center justify-content-center ">
                                            <Button class="flex align-items-center justify-content-center " size="small" label="Add" severity="success" raised @click="newItemColumn"></Button>
                                        </div>
                                    </div>
                                </template> 
                            </Dialog>

                             <!-- store po items -->
                            <Dialog :visible="poItemsDialog" modal :closable="false" header="Items added in PO" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                <DataTable :value="storePoItems" tableStyle="min-width: 30rem">
                                    <Column style="width:3%;" header="Sl No.">
                                        <template #body="slotProps">
                                            {{++slotProps.index}}
                                        </template>
                                    </Column>
                                    <Column field="item_name" header="Item"></Column>
                                    <Column field="packing" header="Packing"></Column>
                                    <Column field="quantity" header="Quantity"></Column>
                                    <Column field="gst" header="GST(%)"></Column>
                                    <Column field="rate" header="Rate"></Column>
                                </DataTable>

                                <div class="flex justify-content-end flex-wrap">
                                    <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" rounded @click="poItemsDialog=false"></Button>
                                </div>
                            </Dialog>
                            <!-- end store po items -->
                     </template>
                </Card>
            </div>
            <div class="col-5">
                <Card style="height:100%;">
                    <template #content >

                        <div class="flex flex-wrap align-content-center justify-content-between">
                            <span class="text-base text-600 font-bold px-4">Pending Items</span>
                            <Divider/>
                        </div>
                        <div>
                            <DataTable :value="filteredItems" scrollable scrollHeight="400px" tableStyle="min-width: 80%" v-model:filters="filters" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]"
                                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                    currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id"
                                    @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" v-model:selection="selectedPoItem" @row-select="addItem" @row-unselect="deleteItem"  >

                                    <template #header >
                                        <div class="flex justify-content-end">
                                            <span class="p-input-icon-left">
                                                <i class="pi pi-search" />
                                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                                            </span>
                                           
                                        </div>
                                    </template>
                                <!-- <Column expander style="width: 5rem" /> -->
                                <Column selectionMode="multiple" headerStyle="width: 3rem" ></Column>
                                <Column field="name" header="Item"></Column>
                                <Column field="deptPoNo" header="Dept PO"></Column>
                                <Column field="packing" header="Packing"></Column>
                                <Column field="rate" header="Rate">
                                    <template #body="slotProps">
                                        {{ formatCurrency(slotProps.data.rate) }}
                                    </template>
                                </Column>
                                <Column field="pendingQty" header="Quantity"></Column>
                                <Column field="category.label" header="Category"></Column>                            
                            </DataTable>
                            
                        </div>
                     </template>
                </Card>
            </div>

            
       
        </div>
    </div>
</div>

</template>

<script>
import Toast from 'primevue/toast'
// import Navbar from '../header/headerNavbar.vue'
import Card from 'primevue/card'
import { getDeptPOList, getManufacturers, getCategories, getLastRate, getOrderCounter, createOemPO, getTermsAndConditions, getStores } from '../../services/apiService'
import Divider from 'primevue/divider'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import Textarea from 'primevue/textarea'
import PoItem from '../oem/oemPoItem.vue'
import Button from 'primevue/button'
import TreeSelect from 'primevue/treeselect';
import { FilterMatchMode } from 'primevue/api'
// import RadioButton from 'primevue/radiobutton'
import Checkbox from 'primevue/checkbox'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import { useOemPoStore } from '@/stores/oemPo'
import Tag from 'primevue/tag'
import { useDeptPoStore } from '@/stores/deptPoStore'
// import RadioButton from 'primevue/radiobutton'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
    components:{ 
        Toast, 
        // Navbar, 
        SideBar,
        Card, 
        Divider, 
        DataTable, 
        Column, 
        Dropdown, 
        InputText, 
        Calendar, 
        InputNumber, 
        Textarea, 
        PoItem, 
        Button,
        TreeSelect, 
        // RadioButton,
        Checkbox,
        Dialog,
        ProgressSpinner,
        Tag,
        // RadioButton,
    },

    data(){
        return{
            oemPoNo:'AMS/',
            expandedRows: [],
            selectedDeptPo:null,
            poNo:null,
            poDate:null,
            pendingItems:[],
            filteredItems:[],
            poValue:0,
            deliveryPeriod:null,
            paymentTerms:null,
            penaltyClause:null,
            otherclause:null,
            flag:false,
            poItems:[],
            id:'',
            name: '',
            rate: '',
            newColData:null,
            selectedCategory: '',
            packing: '',
            itemManufacturer: '',
            manufacturers:[],
            quantity:null,
            subTotal:null,
            totalValue: 0,
            categories:null,
            oemPoDate:null,
            oemPoCategory:null,
            oemManufacturer:null,
            deptPoItems:[],
            selectedPoItem:[],
            deptPoDetails:[],
            showElement:true,
            rateList:null,
            checkBox:false,
            itemPrevOem:null,
            selectedPoItems:[],
            filteredOems:[],
            storeStatus:false,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.IN },
                rate:{ value: null, matchMode: FilterMatchMode.IN },
            },
            addlInfoChoice:false,
            addInfoDialogVisibility:false,
            newColumnHead:null,
            newColumnStatus:false,
            checkStatus:false,
            loadingStatus:false,
            storeDelivery:false,
            stores:[],
            selectedStore:null,
            // storeDropDown:false,
            poItemsDialog:false,
            storePoItems:[],
            poDateElement:false,
            oemPoId:null,
            prevDeptPoRef:null,
        }
    },

    methods:{
        getOrderNo(){
            const query = [];
            if(this.oemManufacturer){
                getOrderCounter(query).then((res)=>{
                        let manufacturer = this.manufacturers.find(({id})=> id == this.oemManufacturer);
                        manufacturer = manufacturer.name.split(/[-, ]/)[0];
                        const today = new Date();
                        let FY = '';
                        if(today.getMonth()>2)
                            FY = today.getFullYear()+'-'+(today.getFullYear()+1);
                        else
                            FY = (today.getFullYear()-1)+'-'+today.getFullYear();
                        
                        // let dept = 'DEPT';
                        // if(this.selectedDeptPo){
                        //     dept = this.selectedDeptPo.split(/[/-]/)[0];
                        // }
                        // this.oemPoNo = 'AMS/'+manufacturer+'/'+dept+'/'+FY+'/'+res.data.counter.toString().padStart(4, '0'); // this includes dept po no.
                        this.oemPoNo = 'AMS/'+manufacturer+'/'+FY+'/'+res.data.counter.toString().padStart(4, '0');
                }).catch((error)=>this.showErrorToast(error.response.data));
            }
        },
        getDeptPOs(){
            this.loadingStatus = true;
            getDeptPOList([]).then((res)=> 
            {           

                this.pendingItems = [];

                for (let i = 0; i < res.data.length; i++) {
                    if(res.data[i].order_status!=1){
                        res.data[i].po_items.forEach(e => {                             
                            if(e.order_status!=1 && e.location_status==1){   
                                // e.deptPoNo = res.data[i].po_no;                             
                                this.pendingItems.push({
                                    ...e,
                                    deptPoNo:res.data[i].po_no
                                    });
                            }
                        });
                    }                    
                }
                this.loadingStatus = false;
                
                this.filteredItems = this.pendingItems;
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
        },
        getPoDetails(){
        
            const query = '?poNo[eq]='+this.selectedDeptPo;
            this.loadingStatus = true;
            getDeptPOList(query).then((res)=> 
            {
                res.data[0].po_items = res.data[0].po_items.filter(this.removeElement);
                this.deptPoDetails = res.data;
                this.filteredItems = res.data[0].po_items;
                this.loadingStatus = false;
            }
            ).catch((error)=>{
                this.showErrorToast(error.response.data)
                this.loadingStatus = false;
            });
        },
        getStoreList(){
            this.loadingStatus = true;
            getStores().then((res)=>{
                this.stores = res.data;
                this.loadingStatus = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.loadingStatus = false;
            })
        },
        removeElement(data){
            return (data.order_status!=1 && data.location_status==1);
        },
        getManufacturersList(){
            getManufacturers().then((res)=> 
            {
                this.manufacturers = res.data
                this.filteredOems = res.data
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
        },
        getCategoriesList(){
            getCategories().then((res) => {
                this.categories = res.data;
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
        },
        async setTemplate(){ 
            this.oemPoCategory ? await getTermsAndConditions(Object.keys(this.oemPoCategory)[0]).then((res)=>{
                if(res.data.data.length>0){
                    return this.paymentTerms = res.data.data[0].content;
                }
                this.paymentTerms = null;
            }).catch((error)=>this.showErrorToast(error.response.data)) : false;


            if(this.oemPoCategory){
                this.filterOems(Object.keys(this.oemPoCategory)[0]);
                this.filterItems(Object.keys(this.oemPoCategory)[0]);
            }
            else{
                this.oemPoCategory = null;
            }

        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        },
        addItem(data){

            const dataItem = data.data;

                const queryData = {
                    'manufacturerId':this.oemManufacturer,
                    'itemId':dataItem.id,
                    'itemName':dataItem.name,
                }

                getLastRate(queryData).then((res)=> 
                {
                    this.rateList = res.data.data;
                    let lastRate = dataItem.rate;
                    let lastGst = 0;

                    res.data.data.length>0 ? lastRate = res.data.data[0].rate : this.showInfoToast({'head':'Rate history'},{'message':'No rates were found for selected item!'});

                    if(res.data.data.length > 0){
                        lastGst = res.data.data[0].gst;
                    }
                        this.poItems.push({
                            id: dataItem.id,
                            name: dataItem.name,
                            rate: lastRate,
                            gst: lastGst,
                            packing: dataItem.packing,
                            quantity:dataItem.pendingQty,
                        });
                }
                ).catch((error)=>this.showErrorToast(error.response.data));        
        },
        bindData(itemData){

            const item = this.poItems.find((e) => e.id == itemData.itemId);

            item.id = itemData.itemId;
            item.name = itemData.itemName;
            item.newColData = itemData.itemNewColData;
            item.rate = itemData.itemRate;
            item.packing = itemData.itemPacking;
            item.itemNop =  itemData.itemNop;
            item.itemPckQty = itemData.itemPckQty;
            item.quantity = itemData.itemQuantity,
            item.gst = itemData.itemGst,
            item.subTotal = itemData.itemSubtotal;
            this.poValue = 0;
            this.poItems.forEach(element => {
                this.poValue += element.subTotal;
            });
           
        },
        deleteItem(data){
            const itemIndex = this.poItems.findIndex((e)=>e.id ==data.data.id);
            this.poValue -= this.poItems[itemIndex].subTotal;
            this.poItems.splice(itemIndex, 1);

            if(this.poItems.length==0){
                this.addlInfoChoice = false;
                this.newColumnStatus = false;
                this.newColumnHead = null;
            }
            return this.poItems;
        },
        setOemData(data){

            this.storeStatus = true;

            this.showEleshowElementment = false;

            this.oemPoId = data.id;
            this.oemPoNo = data.po_no;
            this.oemManufacturer = data.manufacturer.id;

            //formatting po date
            let splitDate = data.po_date.split(/-/);
            let newDate = splitDate[2]+'-'+(splitDate[1])+'-'+splitDate[0]+'T00:00:10';
            this.oemPoDate = new Date(newDate);

            this.poDateElement = true;

            this.paymentTerms = data.terms_and_contions;

            this.storePoItems = data.oem_po_details;
            this.prevDeptPoRef = data.dept_po_ref;
            this.penaltyClause = data.penalty_clause;

            if(data.store_delivery){
                this.storeDelivery = true;
                this.getStoreList();
                this.selectedStore = data.store_delivery.uuid;  
            }
            
            // this.getCategoriesList();
            this.getDeptPOs();           
        },
        setSingleDeptPoOemPoData(data){
        
            this.oemPoCategory = {[data.data.category.key]:true};

            this.setTemplate(this.oemPoCategory);
            if(data.data.ordered_oem.length>0){
                this.oemManufacturer = data.data.ordered_oem[0].id; 
            }

            this.selectedPoItem = [{...data.data}];
            this.addItem(data);
        },
        handleSave(){

            if(this.oemPoDate==null || this.oemManufacturer==null || this.paymentTerms==null ){
                return this.showErrorToast({'message':'Date/Manufacturer/Payment-Terms fields are required!'});
            }

            // this.oemPoCategory = Object.keys(this.oemPoCategory)[0];

            if(this.poItems.length==0){
                return this.showErrorToast({'message':'Atleast one(1) item should be added in the PO!'});
            }

            if(this.storeDelivery == true && this.selectedStore == null){
                return this.showErrorToast({'message':'Store not selected for Store delivery!'});
            }

            const data = {
                'oemPoId':this.oemPoId,
                'manufacturerId':this.oemManufacturer,
                'oemPoNo':this.oemPoNo,
                'oemPoDate':this.oemPoDate.getDate()+'-'+(this.oemPoDate.getMonth()+1)+'-'+this.oemPoDate.getFullYear(),
                'totalPoValue':this.poValue,
                'paymentTerms':this.paymentTerms,
                'penaltyClause':this.penaltyClause,
                // 'oemPoCategory':this.oemPoCategory,
                'poItems':this.poItems,
                'prevDeptPoRef':this.prevDeptPoRef,
                'storeDelivery':this.storeDelivery,
                'selectedStore':this.selectedStore,
            }
            
            this.loadingStatus = true;
            createOemPO(data).then((res)=> 
                {
                    this.showSuccessToast(res.data);
                    this.loadingStatus = false;
                    this.$router.push('oem-po-drafts'); 
                }
            ).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.loadingStatus = false;
            });
        },
        enableElement(){
            this.showElement = false;
            this.checkBox = true;
        },
        filterOems(cat){
            this.filteredOems = [];

            let splitCat = cat.split('-');

            this.manufacturers.forEach(e => {
                if(e.category.length>0){
                    e.category.every(c => {
                        let key=null;
                        let key2 = [];
                        key = c.key;
                        key2 = key.split('-');
                        if(splitCat.length==1){
                            if(key2[0]==cat){
                                this.filteredOems.push(e);
                                return false;
                            }
                        }
                        if(splitCat.length>1){ 
                            if(key2.length>1){
                                if(key2[1]==splitCat[1]){
                                    this.filteredOems.push(e);
                                    return false;
                                }
                            }
                            else{
                                if(key2[0]==splitCat[0]){
                                    this.filteredOems.push(e);
                                    return false;
                                }
                            }
                        }
                        return true;
                    });
                }
                else{
                    this.filteredOems.push(e);
                }
            });
        },
        filterItems(cat){
            this.filteredItems = [];
            this.pendingItems.forEach(e => {
                if(e.category.key==cat){
                    this.filteredItems.push(e);
                }
            });
        },
        showAddltInfoDialog(){
        
            if(this.addlInfoChoice){
                // if(this.newColumnStatus){
                //     this.poItems.forEach(e => {
                //             e.newColData = '';
                //     });
                //     this.newColumnHead = null;
                //     this.newColData = null;
                //     return this.newColumnStatus = false;                    
                // }
                if(this.poItems.length>0){
                    this.poItems.forEach(e => {
                        e.newColData = null;
                    });
                }
                this.addInfoDialogVisibility = true;
            }
            if(!this.addlInfoChoice){
                if(this.poItems.length>0){
                    this.poItems.forEach(e => {
                        e.newColData = null;
                    });
                }
                this.newColumnStatus = false; 
                this.newColumnHead = null;
            }
        },
        hideAddltInfoDialog(){
            this.addInfoDialogVisibility = false;
            this.addlInfoChoice = false;
            this.newColumnStatus = false; 
            this.newColumnHead = null;
        },
        showPoItemsDialog(){
            this.poItemsDialog = true;
        },
        newItemColumn(){
            // if(this.addlInfoChoice){
                if(this.newColumnHead!="" || this.newColumnHead!==null){
                    this.newColumnStatus = true;
                    this.addInfoDialogVisibility = false;
                }
            // }
        },  
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 3000 });
        },   
        showErrorToast(error){
               this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 5000 });
        },
        showInfoToast(head, info){
               this.$toast.add({ severity: 'info', summary: head.head, detail:info.message, life: 5000 });
        },
        checkDeliveryLocation(){
            if(this.storeDelivery == true){
                this.getStoreList();
                // this.storeDropDown = true;
            }
            if(this.storeDelivery == false){
                // this.storeDropDown = false;
                this.selectedStore = null;
            }
        }
    },
    mounted(){
        this.getCategoriesList();

        this.getManufacturersList();

        const store = useOemPoStore();
        if(store.data){
            this.storeStatus = true;
            this.setOemData(store.data);
        }
        else{
            this.getDeptPOs();
        }

        const deptStore = useDeptPoStore();
        if(deptStore.data){
            this.storeStatus = false;
            this.setSingleDeptPoOemPoData(deptStore.data);
        }

    },
    unmounted(){
        const store = useOemPoStore();
        store.data = null;
        this.storeStatus = false;

        const store2 = useDeptPoStore();
        store2.data = null;

        this.storeStatus = false;
    }
}
</script>

<style scoped>

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}


</style>