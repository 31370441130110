import { defineStore } from "pinia";

export const useNotificationStore = defineStore('notificationStore', {
    state:() => ({
        notifications:[],
    }),
    getters:{
        getNotificationCount(state){
            return (state.notifications.length>0 ? state.notifications.length : 0);
        }
    }
})