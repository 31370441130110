// import { createApp, watch } from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/aura-light-teal/theme.css'
import '../node_modules/primeflex/primeflex.css'
import ToastService from 'primevue/toastservice'
import Router from './router'
import 'primeicons/primeicons.css'
import { createPinia } from 'pinia'
import Tooltip from 'primevue/tooltip';
import VueCookies from 'vue-cookies';
// import DialogService from 'primevue/dialogservice';
import ConfirmationService from 'primevue/confirmationservice';
import Ripple from 'primevue/ripple'
import StyleClass from 'primevue/styleclass'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

// import Vue from 'vue';

// import as directive
import BadgeDirective from 'primevue/badgedirective';
import Echo from 'laravel-echo';

/* add icons to the library */
library.add(faUserSecret)

const pinia = createPinia();

const app = createApp(App);

window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKET_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKET_SERVER,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
    encrypted:false,
    auth:{
        headers:{
            'Authorization': 'Bearer '+localStorage.getItem('bearer-token'),
        }
    }
});


app.use(PrimeVue, { ripple: true  });
app.use(ToastService);
app.use(PrimeVue);
app.use(Router);
app.use(pinia);
app.use(VueCookies);
app.use(ConfirmationService);
app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
app.component('font-awesome-icon', FontAwesomeIcon);
app.config.globalProperties.$serviceHsn = '998735';
app.directive('badge', BadgeDirective);
app.mount("#app");
 
// createApp(App).use(ToastService).use(PrimeVue).use(Router).use(pinia).directive('tooltip', Tooltip).use(VueCookies).use(ConfirmationService).mount('#app');





