<template>

  <!-- <div id="app" class="surface-100"> -->
  <div id="app" >
    <Toast />
    <RouterView />
    <DynamicDialog/>
    <Navbar v-if=" $route.meta.hideNavBar ? !$route.meta.hideNavBar : true"/>
    <SideBar v-if=" $route.meta.hideSideBar ? !$route.meta.hideSideBar : true"/>
  </div>
</template>

<script>
import Toast from 'primevue/toast'
import Navbar from './components/header/headerNavbar.vue'
import { RouterView } from 'vue-router'
import DynamicDialog from 'primevue/dynamicdialog';
import SideBar from '../src/components/sidebar/sideBarTwo.vue'
import { useNotificationStore } from './stores/notificationStore'

export default {
  name: 'App',
  
  components: { 
      Toast, 
      RouterView,
      DynamicDialog,
      Navbar,
      SideBar 
    },
    mounted(){
      this.notificationCount = 0;
      const notificationStore = useNotificationStore();   
   
          window.Echo.channel('App.Models.User.'+localStorage.getItem('user-token')).listen('SendCmcDueNotification', (e) => {           
                notificationStore.$patch((state)=>{
                  state.notifications.push(e)
                })          
          });
    },
}
</script>

<style>

html {
  font-size: 13px;
}


.progress-spinner {
    position: fixed;
    z-index: 999;
    /* height: 0.3rem; */
    height: 40px;
    width: 40px;
    /* width: 0.3rem; */
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: rgba(0,0,0,0.53);
}


.main-content {
    height: calc(100% - 18px);
    /* height: 100vh; */
    /* margin-top: 50px; */
    /* padding-top: 55px;
    padding-left: 200px; */
    padding: 55px 10px 5px 220px;
}

</style>
