<template>
<Card class="fixed-menu">
    
    <template #subtitle>
        <ul class="list-none p-0 m-0 overflow-hidden ">
            <li class="btn-container">
                <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:indigo-200 transition-duration-150 transition-colors p-ripple child-btn" @click="this.$router.push('/dashboard')">
                    <i class="pi pi-home mr-2"></i>
                    <span class="text-lg">Dashboard</span>
                </a>
            </li>

            <!-- department po and roc -->
            <li class="btn-container">
                <a
                    v-ripple
                    v-styleclass="{
                        selector: '@next',
                        enterClass: 'hidden',
                        enterActiveClass: 'slidedown',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'slideup'
                    }"
                    class="flex align-items-center cursor-pointer p-3 border-round text-700 transition-duration-150 transition-colors p-ripple" 
                >
                    <i class="pi pi-building mr-2"></i>
                    <!-- <font-awesome-icon icon="fa-solid fa-building-user" /> -->
                    <span class="text-lg">Department</span>
                    <i class="pi pi-chevron-down ml-auto"></i>
                </a>
                <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">

                    <li class="btn-container">
                        <a
                            v-ripple
                            v-styleclass="{
                                selector: '@next',
                                enterClass: 'hidden',
                                enterActiveClass: 'slidedown',
                                leaveToClass: 'hidden',
                                leaveActiveClass: 'slideup'
                            }"
                            class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple"
                        >
                            <i class="pi pi-file mr-2"></i>
                            <span class="text-lg">PO</span>
                            <i class="pi pi-chevron-down ml-auto"></i>
                        </a>
                        <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                        <!-- <ul class="list-none py-0 pl-3 pr-0 m-0 hidden transition-all transition-duration-400 transition-ease-in-out"> -->
                            <li class="btn-container">
                                <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/add-dept-po')">
                                    <i class="pi pi-file mr-2"></i>
                                    <span class="text-lg">New PO</span>
                                </a>
                            
                            </li>
                            <li class="btn-container">
                                <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/dept-po-list')">
                                    <i class="pi pi-bars mr-2"></i>
                                    <span class="text-lg">All POs</span>
                                </a>
                            </li>
                        </ul>
                    </li>
            
                <!-- roc start -->
                <li class="btn-container">
                    <a
                        v-ripple
                        v-styleclass="{
                            selector: '@next',
                            enterClass: 'hidden',
                            enterActiveClass: 'slidedown',
                            leaveToClass: 'hidden',
                            leaveActiveClass: 'slideup'
                        }"
                        class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple"
                    >
                        <i class="pi pi-file mr-2"></i>
                        <span class="text-lg">ROC</span>
                        <i class="pi pi-chevron-down ml-auto"></i>
                    </a>
                    <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                        <li class="btn-container">
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/add-roc')">
                                <i class="pi pi-file mr-2"></i>
                                <span class="text-lg">New ROC</span>
                            </a>
                        
                        </li>
                        <li class="btn-container">
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/roc-list')">
                                <i class="pi pi-bars mr-2"></i>
                                <span class="text-lg">All ROCs</span>
                            </a>
                        </li>
                    </ul>
                </li>
                        <!-- roc end -->

                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/departments')">
                            <i class="pi pi-bars mr-2"></i>
                            <span class="text-lg">Department List</span>
                        </a>
                    </li>
                </ul>
            </li>
            <!-- dept po and roc end -->


            <!-- oem po  -->
            <li class="btn-container">
                <a
                    v-ripple
                    v-styleclass="{
                        selector: '@next',
                        enterClass: 'hidden',
                        enterActiveClass: 'slidedown',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'slideup'
                    }"
                    class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" 
                >
                    <i class="pi pi-building mr-2"></i>
                    <span class="text-lg">OEM</span>
                    <i class="pi pi-chevron-down ml-auto"></i>
                </a>
                <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">

                <!-- roc start -->
                <li class="btn-container">
                    <a
                        v-ripple
                        v-styleclass="{
                            selector: '@next',
                            enterClass: 'hidden',
                            enterActiveClass: 'slidedown',
                            leaveToClass: 'hidden',
                            leaveActiveClass: 'slideup'
                        }"
                        class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple"
                    >
                        <i class="pi pi-file mr-2"></i>
                        <span class="text-lg">PO</span>
                        <i class="pi pi-chevron-down ml-auto"></i>
                    </a>
                    <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                        <li class="btn-container">
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/add-oem-po')">
                                <i class="pi pi-file mr-2"></i>
                                <span class="text-lg">New PO</span>
                            </a>
                    
                        </li>
                        <li class="btn-container">
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/oem-po-drafts')">
                                <i class="pi pi-file-edit mr-2"></i>
                                <span class="text-lg">Draft PO</span>
                            </a>
                    
                        </li>
                        <li class="btn-container">
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/oem-po-list')">
                                <i class="pi pi-check-square mr-2"></i>
                                <span class="text-lg">Approved PO</span>
                            </a>
                        </li>
                        <li class="btn-container">
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/oem-po-cancelled')">
                                <i class="pi pi-file-excel mr-2"></i>
                                <span class="text-lg">Cancelled PO</span>
                            </a>
                        </li>
                    </ul>
                </li> 

                <li class="btn-container">
                    <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/manufacturer-list')">
                        <i class="pi pi-bars mr-2"></i>
                        <span class="text-lg">Oem List</span>
                    </a>
                </li>                                           
                    
                </ul>
            </li >
            <!-- oem po end -->
            
            <li class="btn-container">
                <a
                    v-ripple
                    v-styleclass="{
                        selector: '@next',
                        enterClass: 'hidden',
                        enterActiveClass: 'slidedown',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'slideup'
                    }"
                    class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple"
                >
                    <i class="pi pi-truck mr-2"></i>
                    <span class="text-lg">Delivery Challan</span>
                    <i class="ml-2 pi pi-chevron-down ml-auto"></i>
                </a>
                <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                <!-- <ul class="list-none py-0 pl-3 pr-0 m-0 hidden transition-all transition-duration-400 transition-ease-in-out"> -->
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/delivery-challan-bulk')">
                            <i class="pi pi-file mr-2"></i>
                            <span class="text-lg">New Challan</span>
                        </a>
                    </li>
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/delivery-challan')">
                            <i class="pi pi-bars mr-2"></i>
                            <span class="text-lg">All Challans</span>
                        </a>
                    </li>
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/cancelled-delivery-challan')">
                            <i class="pi pi-file-excel mr-2"></i>
                            <span class="text-lg">Cancelled Challans</span>
                        </a>
                    </li>
                </ul>
            </li>
        
        <!-- stock arrival tab -->
            <li class="btn-container">
                <a
                    v-ripple
                    v-styleclass="{
                        selector: '@next',
                        enterClass: 'hidden',
                        enterActiveClass: 'slidedown',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'slideup'
                    }"
                    class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple"
                >
                    <i class="pi pi-box mr-2"></i>
                    <span class="text-lg">Stock</span>
                    <i class="ml-2 pi pi-chevron-down ml-auto"></i>
                </a>
                <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                <!-- <ul class="list-none py-0 pl-3 pr-0 m-0 hidden transition-all transition-duration-400 transition-ease-in-out"> -->
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/stock-arrival')">
                            <i class="pi pi-arrow-circle-down mr-2"></i>
                            <span class="text-lg">Stock Arrival</span>
                        </a>
                    </li>
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/invoice-details')">
                            <i class="pi pi-calculator mr-2"></i>
                            <span class="text-lg">Invoices</span>
                        </a>
                    </li>
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/inventory')">
                            <i class="pi pi-database mr-2"></i>
                            <span class="text-lg">Store Inventory</span>
                        </a>
                    </li>
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/store-details')">
                            <i class="pi pi-home mr-2"></i>
                            <span class="text-lg">Stores</span>
                        </a>
                    </li>
                </ul>
            </li>
            <!-- stock arrival tab end -->

            <!-- bill start -->
            <li class="btn-container">
                <a
                    v-ripple
                    v-styleclass="{
                        selector: '@next',
                        enterClass: 'hidden',
                        enterActiveClass: 'slidedown',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'slideup'
                    }"
                    class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple"
                >
                    <i class="pi pi-money-bill mr-2"></i>
                    <span class="text-lg">Billing</span>
                    <i class="ml-2 pi pi-chevron-down ml-auto"></i>
                </a>
                <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                <!-- <ul class="list-none py-0 pl-3 pr-0 m-0 hidden transition-all transition-duration-400 transition-ease-in-out"> -->
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/add-new-bill')">
                            <i class="pi pi-plus-circle mr-2"></i>
                            <span class="text-lg">New Bill</span>
                        </a>
                    </li>
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/bill-list-draft')">
                            <i class="pi pi-file-edit mr-2"></i>
                            <span class="text-lg">Bills Draft</span>
                        </a>
                    </li>
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/bill-list')">
                            <i class="pi pi-check-square mr-2"></i>
                            <span class="text-lg">Approved Bills</span>
                        </a>
                    </li>
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700 transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/bill-list-cancelled')">
                            <i class="pi pi-file-excel mr-2"></i>
                            <span class="text-lg">Cancelled Bills</span>
                        </a>
                    </li>
                </ul>
            </li>
            <!-- bill end -->

            <!-- add on start -->
            <li class="btn-container">
                <a
                    v-ripple
                    v-styleclass="{
                        selector: '@next',
                        enterClass: 'hidden',
                        enterActiveClass: 'slidedown',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'slideup'
                    }"
                    class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple"
                >
                    <i class="pi pi-th-large mr-2"></i>
                    <span class="text-lg">Add On</span>
                    <i class="ml-2 pi pi-chevron-down ml-auto"></i>
                </a>
                <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700 transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/terms-and-conditions')">
                            <i class="pi pi-book mr-2"></i>
                            <span class="text-lg">Terms & Conditions</span>
                        </a>
                    </li>
                    <li class="btn-container">
                        <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700  transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/categories')">
                            <i class="pi pi-sliders-h mr-2"></i>
                            <span class="text-lg">Categories</span>
                        </a>
                    </li>
                </ul>
            </li>
            <!-- add on end -->

            <!-- reports start -->
            <li class="btn-container">
                <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700 transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/mis-reports')">
                    <i class="pi pi-chart-bar mr-2"></i>
                    <span class="text-lg">Reports</span>
                </a>
            </li>
            <!-- reports end -->


            <!-- equipments  -->
            <li class="btn-container">
                <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700 transition-duration-150 transition-colors p-ripple" @click="this.$router.push('/equipment-installation')">
                    <i class="pi pi-wrench mr-2"></i>
                    <span class="text-lg">Installation & Servicing</span>
                </a>
            </li>
            <!-- equipments end -->

        </ul>
                            
    </template>
</Card>
</template>

<script>
import Card from 'primevue/card'


export default {
    
    components:{
      Card,
    },
    data() {
        return {
            visible: false
        }
    },
    
}
</script>

<style scoped>

.fixed-menu {
    position: fixed;
    background-color: white;
    top:4.1rem ;
    z-index: 990;
    width: 210px;
    margin-right: 5rem;
    margin-left: 0;
    /* height: 100vh; */
    height: calc(100vw - 60px);
    /* position: relative; */
    /* overflow-y: scroll; */
    overflow-x: hidden;
    transition: all 0.5s ease-in-out;
}

.btn-container a:hover {
    background-color: #c7eeea;
}

.btn-container a:active {
    background-color: #6dd4c8;
    /* color: #6dd4c8; */
    /* border-left: 1px solid #6dd4c8; */
}

@media print {
  body {
    visibility: hidden;
  }
}

/* .child-btn{
    background-color: #6dd4c8;
} */

</style>