<template>
  <!-- <Navbar/> -->
  <SideBar/>
    <div class="card main-content">
        <div class="surface-ground ">
            <Card>
                <template #content>
                    <DataTable :value="categories" :loading="loading" tableStyle="min-width: 50rem" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]"
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id"
                            @rowExpand="onRowExpand" @rowCollapse="onRowCollapse">
                            <template #header >
                                <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                    <span class="text-xl text-900 font-bold">Category List</span>
                                    <Button class="p-button-sm" size="small" label="New Category" icon="pi pi-plus" severity="info" raised @click="showCatDialog"/>
                                </div>
                            </template>
                        <Column expander style="width: 5rem" />
                        <Column field="label" header="Category"></Column>
                        <Column style="width:70%" field="description" header="Description"></Column>
                        <Column header="Action">
                            <template #body="slotProps">
                                <Button class="py-2 px-3" icon="pi pi-plus" label="Sub Category" size="small" @click="showSubCatDialog(slotProps.data)"/>
                            <Dialog :visible="subCatDialogVisibility" modal :closable="false" header="Sub Category" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                <div class="formgrid grid mt-2">
                                    <div class="field col">
                                        <label class="font-bold block" for="parentCategory">Parent Category:</label>   
                                        <InputText v-model="parentCategory" optionValue="id" optionLabel="name" class="w-full" readonly/>
                                    </div>
                                    <div class="field col">
                                        <label class="font-bold block" for="subCategory">Sub Category:</label>   
                                        <InputText v-model="subCategory" optionValue="id" optionLabel="name" class="w-full"/>
                                    </div>
                                </div>
                                <div class="formgrid grid mt-2">
                                    <div class="field col">
                                        <label class="font-bold block" for="paymentTerms">Description:</label>                             
                                        <Textarea class="surface-overlay w-full" v-model="subCategoryDescrip" rows="5"/>
                                    </div>
                                </div>

                                <template #footer>
                                    <div class="flex flex-row-reverse flex-wrap">
                                        <div class="flex align-items-center justify-content-center ">
                                            <Button class="flex align-items-center justify-content-center " size="small" label="Close" severity="danger" @click="hideSubCatDialog"></Button>
                                        </div>
                                        <div class="flex align-items-center justify-content-center">
                                            <Button class="flex align-items-center justify-content-center " size="small" label="Save" icon="pi pi-save" severity="success" @click="handleSubCategorySave"/>
                                        </div>
                                    </div>
                                </template>
                            </Dialog>
                            </template>
                        </Column>

                        <template #expansion="slotProps">
                            <div class="p-3">
                                <div class="flex flex-wrap align-items-center justify-content-between">
                                    <span class="text-base text-600 font-bold px-4 "><h3>Sub Categories</h3></span>
                                </div>
                                <DataTable :value="slotProps.data.children">
                                    <Column field="label" header="Category"></Column>
                                    <Column field="sub_description" header="Description"></Column>
                                </DataTable>
                            </div>
                        </template>

                    </DataTable>
                        <!-- New category dialog start -->
                        <Dialog :visible="catDialogVisibility" modal :closable="false" header="New Category" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                            <div class="formgrid grid mt-2">
                                <div class="field col">
                                    <label class="font-bold block" for="parentCategory">Categories available:</label>   
                                    <!-- <Dropdown v-model="categories" :options="categories" optionValue="id" optionLabel="label" class="w-full" readonly/> -->
                                    <TreeSelect v-model="categoryList" :options="categories" placeholder="Category list" class="w-full" readonly/>
                                </div>
                            </div>
                            <div class="formgrid grid mt-2">
                                 <div class="field col">
                                    <label class="font-bold block" for="subCategory">New Category:</label>   
                                    <InputText v-model="category" optionValue="id" optionLabel="name" class="w-full"/>
                                </div>
                            </div>
                            <div class="formgrid grid mt-2">
                                    <div class="field col">
                                        <label class="font-bold block" for="paymentTerms">Description:</label>                             
                                        <Textarea class="surface-overlay w-full" v-model="categoryDescription" rows="5"/>
                                    </div>
                                </div>
                            <template #footer>
                                <div class="flex flex-row-reverse flex-wrap">
                                    <div class="flex align-items-center justify-content-center ">
                                        <Button class="flex align-items-center justify-content-center " size="small" label="Close" severity="danger" @click="hideCatDialog"></Button>
                                    </div>
                                    <div class="flex align-items-center justify-content-center">
                                        <Button class="flex align-items-center justify-content-center " size="small" label="Save" icon="pi pi-save" severity="success" @click="handleCategorySave"/>
                                    </div>
                                </div>
                            </template>
                        </Dialog>
                        <!-- new category dialog end -->
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button'
// import Navbar from '../header/headerNavbar.vue'
import DataTable from 'primevue/datatable'
import { getCategories, createSubCategory, createCategory } from '@/services/apiService'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import Card from 'primevue/card'
// import Dropdown from 'primevue/dropdown'
import TreeSelect from 'primevue/treeselect'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
    
    components:{
        // Dropdown,
        // Navbar,
        Button,
        DataTable,
        Column,
        Dialog,
        InputText,
        Textarea,
        Card,
        TreeSelect,
        SideBar
    },
    data(){
        return{
            loading:false,
            categories:[],
            categoryList:null,
            catDialogVisibility:false,
            subCatDialogVisibility:false,
            parentCategory:null,
            parentCategoryId:null,
            category:null,
            categoryDescription:null,
            subCategory:null,
            subCategoryDescrip:null,
            // description:null,
            expandedRows:[],
        }
    },
    methods:{
        getCategory(){
            this.loading = true;
            getCategories().then((res)=> 
                {
                    res.data.forEach(e => {
                        e.children.forEach(el => {
                            el.subLable = el.label;
                        });
                    });
                    this.categories = res.data;
                    this.loading = false;
                }
                ).catch((error)=>this.showErrorToast(error.response.data));
        },
        handleSubCategorySave(){
            const data = {
                'parentCategoryId':this.parentCategoryId,
                'subCategory':this.subCategory,
                'subCategoryDescrip':this.subCategoryDescrip,
            };

            createSubCategory(data).then((res)=>{
                this.showSuccessToast(res.data);
                this.hideSubCatDialog();
                this.getCategory();
            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        showCatDialog(){
            this.getCategory();
            this.catDialogVisibility = true;
        },
        hideCatDialog(){
            this.catDialogVisibility = false;
        },
        handleCategorySave(){
            const data = {
                'name':this.category,
                'description':this.categoryDescription,
            }

            createCategory(data).then((res)=>{
                this.showSuccessToast(res.data);
                this.hideCatDialog();
                this.getCategory();
            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        showSubCatDialog(data){
            this.parentCategoryId = data.key;
            this.parentCategory = data.label;
            this.subCatDialogVisibility = true;
        },
        hideSubCatDialog(){
            this.subCatDialogVisibility = false;
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        }, 
    },
    mounted(){
        this.getCategory();
    }

}
</script>

<style>

</style>