
<template>
<!-- <Navbar/> -->
<SideBar/>
<div class="card main-content">

    <div class="progress-spinner" v-if="spinnerLoadingStatus">
        <ProgressSpinner></ProgressSpinner>
    </div>

    <div class="surface-ground ">
        <Card>
            <template #content>
            <DataTable :loading="loading" :value="manufacturers" v-model:filters="filters" tableStyle="min-width: 50rem" size="small" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse">
                <template #header >
                    <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                        <span class="text-xl text-900 font-bold">Manufacturer List</span>
                        <span>
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" class="mr-2"/>
                            <Button class="p-button-sm" size="small" label="New OEM" icon="pi pi-plus" severity="info" raised @click="showDialog"/>
                        </span>
                    </div>
                </template>
                <Column field="name" header="Manufacturer Name."></Column>
                <Column field="address" header="Address"></Column>
                <Column field="phone" header="Phone No."></Column>
                <Column field="email" header="Email Id"></Column>
                <Column field="gst" header="GST No."></Column>
                <Column field="tan" header="TAN"></Column>
                <Column field="pan" header="PAN"></Column>
                <Column header="Contact Person">
                    <template #body="slotProps">
                        <label for="">{{slotProps.data.contact_person}}<br>({{slotProps.data.contact_phone}})</label>
                    </template>
                </Column>
                <Column header="Mapping">
                    <template #body="slotProps">
                        <Button  icon="pi pi-sitemap" severity="success" @click="showMappingDialog(slotProps.data)"/>
                    </template>
                </Column>
                <Column header="Action">
                    <template #body="slotProps">
                        <Button size="small" class="py-2" label="Update" @click="showOem(slotProps.data)"/>
                    </template>
                </Column>
                <template #footer> In total there are {{ manufacturers ? manufacturers.length : 0 }} manufacturers. </template>

                <!-- <Dialog :visible="showOemDialog" modal :closable="false" header="Update OEM" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

                </Dialog> -->
            </DataTable>

            <!-- mapping dialog -->
            <Dialog :visible="mappingDialogVisibility" modal :closable="false" showHeader="false" :style="{ width: '60rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                    
                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex align-items-center justify-content-center"><h3>{{oemName}}</h3></div>
                        <div class="flex align-items-center justify-content-center"><ProgressSpinner v-if="loadingStatus" style="width: 50px; height: 50px" strokeWidth="5"/></div>
                    </div>

                        <div class="card flex">
                            <div class="w-full">
                                <ul>
                                    <h3>Mapped with:</h3>
                                    <li v-for="(item, index) in mappedData" :key="index">{{item['parent']}} <label v-if="item['sub_category']">-{{item['sub_category']}}</label> <Button size="small" class="mt-1 px-1 py-1" icon="pi pi-sort-alt-slash" severity="success" v-tooltip.top="'Unmap'" @click="showConfirmDialog(item)"/> </li>
                                </ul>
                            </div>
                            <Divider layout="vertical"/>
                            <div class="w-full">
                                <h3 class="w-full">Map new category:</h3>
                                <TreeSelect class="w-full" :options="categories" v-model="selectedMapCategory"/>
                            </div>
                        </div>
                    
                <template #footer>
                    <div class="flex flex-row-reverse flex-wrap">
                        <div class="flex align-items-center justify-content-center ">
                            <Button class="flex align-items-center justify-content-center " size="small" label="Close" severity="danger" @click="hideMappingDialog"></Button>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <Button class="flex align-items-center justify-content-center " size="small" label="Map" icon="pi pi-save" severity="success" @click="saveMapping"/>
                        </div>
                    </div>
                </template>
            </Dialog>

            <!-- confirmation dialog for unmapping start -->
            <Dialog :visible="confirmDialogVisibility" position="top" modal :closable="false" header="Confirm Unmapping" :style="{ width: '20rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

                <p>This will remove the mapping of the OEM with the category. Are you sure you want to proceed?</p>
                <template #footer>
                    <div class="flex flex-row-reverse flex-wrap">
                        <div class="flex align-items-center justify-content-center ">
                            <Button class="flex align-items-center justify-content-center " size="small" label="Close" severity="danger" @click="hideConfirmDialog"></Button>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <Button class="flex align-items-center justify-content-center " size="small" label="Unmap" icon="pi pi-save" severity="success" @click="unmapManufacturer(confirmUnmapData)"/>
                        </div>
                    </div>
                </template>
            </Dialog>
            <!-- confirmation dialog for unmapping end -->
            <!-- end mapping dialog -->
            </template>
        </Card>
    </div>


    <Dialog :visible="dialogVisibility" modal :closable="false" header="OEM Details" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <!-- <template #header>
            <div class="inline-flex align-items-center justify-content-center gap-2">
                 <span class="font-bold white-space-nowrap">OEM Details</span>
              </div>
         </template> -->
         <Divider />
         <div>
            <label class="text-sm text-500">Fields marked with <span class="text-red-500">*</span> are mandatory</label>
        </div>

        <InputText hidden v-model="oemId" />
        <div class="formgrid grid mt-2">
            <div class="field col">
                <label for="firstname2">Name:<span class="text-red-500">*</span></label>
                <InputText v-model="oemName" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            <div class="field col">
                <label for="lastname2">Email:</label>
                <InputText v-model="oemEmail" type="email" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
                <InlineMessage v-if="emailError">Invalid Email</InlineMessage>
            </div>
            <div class="field col">
                <label for="lastname2">GST:<span class="text-red-500">*</span></label>
                <InputText v-model="oemGst" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
        </div>

        <div class="formgrid grid mt-2">
            <div class="field col">
                <label for="oemTan">TAN:</label>
                <InputText v-model="oemTan" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full" maxlength="10"/>
            </div>
            <div class="field col">
                <label for="oemPan">PAN:</label>
                <InputText v-model="oemPan" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full" maxlength="10"/>
            </div>
        </div>

         <div class="formgrid grid mt-2">
            <div class="field col">
                <label for="firstname2">House/Flat No.:</label>
                <InputText v-model="oemHouseNo" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            <div class="field col">
                <label for="lastname2">Locality:</label>
                <InputText v-model="oemLocality" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            <div class="field col">
                <label for="firstname2">City:<span class="text-red-500">*</span></label>
                <InputText v-model="oemCity" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
        </div>

        <!-- 2nd row -->
        <div class="formgrid grid">
            <div class="field col">
                <label for="lastname2">State:<span class="text-red-500">*</span></label>
                <Dropdown class="surface-overlay w-full" :options="indianStates" filter optionValue="name" optionLabel="name" v-model="oemState" />
            </div>
             <div class="field col">
                <label for="firstname2">Pincode:<span class="text-red-500">*</span></label>
                <InputNumber v-model="oemPincode" :useGrouping="false" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            <div class="field col">
                <label for="lastname2">Contact:<span class="text-red-500">*</span></label>
                <InputMask v-model="oemContact"  mask="9999999999" placeholder="99xxxxxxxx" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
        </div>

        <Divider align="left" type="solid">
            <b>Contact Person:</b>
        </Divider>
        <div class="formgrid grid">
            <div class="field col">
                <label for="firstname2">Name</label>
                <InputText v-model="oemPersonName" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            <div class="field col">
                <label for="lastname2">Contact</label>
                <InputMask v-model="oemPersonContact" mask="9999999999" placeholder="99xxxxxxxx" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
        </div>


        <template #footer>
            <div class="flex flex-row-reverse flex-wrap">
                <div class="flex align-items-center justify-content-center ">
                    <Button class="flex align-items-center justify-content-center ml-2" size="small" label="Close" severity="secondary" text raised  @click="hideDialog"></Button>
                </div>
                <div v-if="!updateBtn" class="flex align-items-center justify-content-center">
                    <Button class="flex align-items-center justify-content-center " size="small" label="Save" icon="pi pi-save" severity="success" text raised @click="handleSave"/>
                </div>
                <div v-if="updateBtn" class="flex align-items-center justify-content-center">
                    <Button class="flex align-items-center justify-content-center " size="small" label="Update" icon="pi pi-save" severity="success" text raised @click="updateOem"/>
                </div>
            </div>
        </template>
    </Dialog>


</div>
 
</template>


<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Card from 'primevue/card';
import { createManufacturer, getCategories, getCategoryMapping, getManufacturers, saveManufacturerCategoryMapping, unmapManufacturer, updateManufacturer } from '../../services/apiService'
// import Navbar from '../header/headerNavbar.vue'
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputMask from 'primevue/inputmask';
import InlineMessage from 'primevue/inlinemessage';
import Dropdown from 'primevue/dropdown';
import TreeSelect from 'primevue/treeselect';
import ProgressSpinner from 'primevue/progressspinner';
import { FilterMatchMode } from 'primevue/api';
import SideBar from '../sidebar/sideBarTwo.vue'


export default {
    components:{
        // Navbar, 
        SideBar,
        DataTable, 
        Column, 
        Card, 
        Button, 
        Dialog, 
        Divider,
        InputText,
        InputNumber,
        InputMask,
        InlineMessage,
        Dropdown, 
        TreeSelect,
        ProgressSpinner,
        },
    data() {
        return {
            manufacturers : null,
            loadingStatus:false,
            spinnerLoadingStatus:false,
            expandedRows: [],
            dialogVisibility:false,
            oemName:null,
            oemEmail:null,
            oemGst:null,
            oemHouseNo:null,
            oemLocality:null,
            oemCity:null,
            oemState:null,
            oemPincode:null,
            oemContact:null,
            oemPersonName:null,
            oemPersonContact:null,
            oemContactDetails:null,
            emailError:false,
            indianStates:[
                {name:'Andaman and Nicobar Islands'},
                {name:'Andhra Pradesh'},
                {name:'Arunachal Pradesh'},
                {name:'Assam'},
                {name:'Bihar'},
                {name:'Chandigarh'},
                {name:'Chhattisgarh'},
                {name:'Dadra and Nagar Haveli'},
                {name:'Daman and Diu'},
                {name:'Delhi'},
                {name:'New Delhi'},
                {name:'Goa'},
                {name:'Gujarat'},
                {name:'Haryana'},
                {name:'Himachal Pradesh'},
                {name:'Jammu and Kashmir'},
                {name:'Jharkhand'},
                {name:'Karnataka'},
                {name:'Kerala'},
                {name:'Ladakh'},
                {name:'Lakshadweep'},
                {name:'Madhya Pradesh'},
                {name:'Maharashtra'},
                {name:'Manipur'},
                {name:'Meghalaya'},
                {name:'Mizoram'},
                {name:'Nagaland'},
                {name:'Mizoram'},
                {name:'Odisha'},
                {name:'Puducherry'},
                {name:'Punjab'},
                {name:'Rajasthan'},
                {name:'Sikkim'},
                {name:'Tamil Nadu'},
                {name:'Telangana'},
                {name:'Tripura'},
                {name:'Uttar Pradesh'},
                {name:'Uttarakhand'},
                {name:'West Bengal'},
            ],
            oemId:null,
            oemTan:null,
            oemPan:null,
            showOemDialog:false,
            mappingDialogVisibility:false,
            confirmDialogVisibility:false,
            updateBtn:false,
            categories:[],
            confirmUnmapData:null,
            selectedMapCategory:null,
            mappedData:[],
            loading:false,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.IN },
                email:{ value: null, matchMode: FilterMatchMode.IN },
                gst:{ value: null, matchMode: FilterMatchMode.IN },
                tan:{ value: null, matchMode: FilterMatchMode.IN },
                pan:{ value: null, matchMode: FilterMatchMode.IN },           
            },
        };
    },

    methods:{
        getManufacturer(){
            this.loading = true;
            getManufacturers().then((res)=> 
            {
                this.manufacturers = res.data
                this.loading = false;
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
        },  
        getCategoriesList(){
            getCategories().then((res) => {
                this.categories = res.data;
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
        },
        getSeverity(product){
            switch (product.status) {
                case 1:
                    return 'success'
                case -1:
                    return 'danger';
                default:
                    break;
            }
        },
        showDialog(){
            this.dialogVisibility = true;
            this.updateBtn = false;
        },
        showMappingDialog(data){

            this.getCategoriesList();
            this.oemId = data.id;
            this.oemName = data.name;
            getCategoryMapping(this.oemId).then((res)=>{
                this.mappedData = res.data.data;               
                this.mappingDialogVisibility = true;
            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        hideMappingDialog(){
            this.mappingDialogVisibility = false;
            this.oemId = null;
            this.oemName = null;
        },
        hideDialog(){
            this.dialogVisibility = false;
            this.oemName = null;
            this.oemEmail = null;
            this.oemGst = null;
            this.oemTan = null;
            this.oemPan = null;
            this.oemHouseNo = null;
            this.oemLocality = null;
            this.oemCity = null;
            this.oemState = null;
            this.oemPincode = null;
            this.oemContact = null;
            this.oemPersonName = null;
            this.oemPersonContact = null;
            this.emailError = false;
        },
        showConfirmDialog(data){
            this.confirmDialogVisibility = true;
            this.confirmUnmapData = data;
        },
        hideConfirmDialog(){
            this.confirmDialogVisibility = false;
        },
        validateEmail(email) {
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                this.emailError = false
            } else {
                this.emailError = true
            }
        },
        handleSave(){            

            if(this.oemName==null || this.oemName=="" || this.oemGst==null || this.oemGst=="" || this.oemPincode==null || this.oemPincode=="" || this.oemState==null || this.oemState=="" || this.oemCity==null || this.oemCity=="" || this.oemContact==null || this.oemContact==""){
                return this.showErrorToast({'message': 'Fields marked with * are required!'})
            }
            const data = {
                'name':this.oemName,
                'email':this.oemEmail,
                'gst':this.oemGst,
                'tan':this.oemTan,
                'pan':this.oemPan,
                'houseNo':this.oemHouseNo,
                'locality':this.oemLocality,
                'city':this.oemCity,
                'state':this.oemState,
                'pincode':this.oemPincode,
                'contact':this.oemContact,
                'personName':this.oemPersonName,
                'personContact':this.oemPersonContact
            };

            this.hideDialog();
            this.spinnerLoadingStatus = true;
            createManufacturer(data).then((res)=> 
            {
                this.showSuccessToast(res.data)
                this.$router.push('manufacturer-list'); 
                this.spinnerLoadingStatus = false;
                this.getManufacturer();
            }
            ).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });
        },
        saveMapping(){
            const data = {
                'oemId':this.oemId,
                'category':this.selectedMapCategory,
            }            
            this.loadingStatus = true;
            saveManufacturerCategoryMapping(data).then((res)=>{
                this.showSuccessToast({'message':res.data.message});
                this.hideMappingDialog();
                this.loadingStatus = false;
            }).catch((error)=>{this.showErrorToast(error.response.data); this.loadingStatus = false;});
                
        },
        unmapManufacturer(data){
            const data2 ={
                'oemId':this.oemId,
                'data':data
            };            
            unmapManufacturer(data2).then((res)=>{
                this.showSuccessToast({'message':res.data.message});
                this.hideConfirmDialog();
                this.hideMappingDialog();
                this.getManufacturer();
            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        showOem(data){
            this.oemId = data.id;
            this.oemName = data.name;
            this.oemEmail = data.email;
            this.oemGst = data.gst;
            this.oemTan = data.tan;
            this.oemPan = data.pan;
            this.oemHouseNo = data.house_no;
            this.oemLocality = data.locality;
            this.oemCity = data.city;
            this.oemState = data.state;
            this.oemPincode = data.pincode;
            this.oemContact = data.phone;
            this.oemPersonName = data.contact_person;
            this.oemPersonContact = data.contact_phone;
            this.updateBtn = true;
            this.dialogVisibility = true;
        },
        updateOem(){

            if(this.oemName==null || this.oemName=="" || this.oemGst==null || this.oemGst=="" || this.oemPincode==null || this.oemPincode=="" || this.oemState==null || this.oemState=="" || this.oemCity==null || this.oemCity=="" || this.oemContact==null || this.oemContact==""){
                return this.showErrorToast({'message': 'Fields marked with * are required!'})
            }

            const id = this.oemId
            const data = {
                'name':this.oemName,
                'email':this.oemEmail,
                'gst':this.oemGst,
                'tan':this.oemTan,
                'pan':this.oemPan,
                'houseNo':this.oemHouseNo,
                'locality':this.oemLocality,
                'city':this.oemCity,
                'state':this.oemState,
                'pincode':this.oemPincode,
                'contact':this.oemContact,
                'personName':this.oemPersonName,
                'personContact':this.oemPersonContact,
            };

            this.hideDialog();
            this.spinnerLoadingStatus = true;

            updateManufacturer(data, id).then((res)=>{
                if(res.status==204 || res.status==200){
                    this.showSuccessToast({'message':'OEM Updated successfully'});
                }
                if(res.status==404){
                    this.showErrorToast({'message':'Record not found!'});
                }
                this.getManufacturer();
                this.spinnerLoadingStatus = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 5000 });
        },
    },
    watch:{
        oemEmail(value){
            this.oemEmail = value;
            this.validateEmail(value);
        }
    },
    mounted() {
        this.getManufacturer()
        
    }
};
</script>
