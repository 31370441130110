<template>
  <!-- <Navbar/> -->
  <SideBar/>
    <div class="card main-content">
        <div class="surface-ground ">
            <Card>
                <template #content>
                    <DataTable :value="oemPos" :size="small" :loading="loadingStatus" v-model:filters="filters" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" @row-edit-save="onParentRowEditSave">
                        <template #header >
                            <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                <span class="text-xl text-900 font-bold">OEM PO <Tag value="CANCELLED" severity="danger" class="mx-3"/> </span>
                                <div class="flex justify-content-end">
                                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                                </div>
                            </div>
                        </template>
                            <Column expander style="width: 5rem"/>
                            <Column field="po_no" header="PO No."/>
                            <Column field="dept_po_ref" header="PO Ref">
                                <template #body="slotProps">
                                    <label v-for="(po, index) in slotProps.data.dept_po_ref" :key="index">
                                        <div class="py-1">{{po}} </div>
                                    </label>
                                </template>
                            </Column>
                            <Column field="manufacturer.name" header="To"></Column>
                            <Column field="po_date" header="PO Date" style="width:10%">
                            </Column>
                            <Column field="total_value" header="PO Value"></Column>
                            <Column header="Terms & Conditions">
                                <template #body="slotProps">
                                    <Inplace :closable="true" closeicon>
                                        <template #display>
                                            {{ 'Show' }}
                                        </template>
                                        <template #content>
                                            <p>{{ slotProps.data.terms_and_contions }}</p> 
                                        </template>

                                    </Inplace>
                                </template>
                            </Column>
                            
                            <template #expansion="slotProps">
                                <div class="p-3">
                                    
                                    <DataTable :value="slotProps.data.oem_po_details" size="small">
                                        <Column field="item_name" header="Item"></Column>
                                        <Column header="Cat/Part-No">
                                            <template #body="slotProps">
                                                {{slotProps.data.add_on_data}}
                                            </template>
                                        </Column>
                                        <Column field="packing" header="Packing">
                                        </Column>
                                        <Column field="quantity" header="Quantity"></Column>
                                        <Column field="rate" header="Rate">
                                            <template #body="slotProps">
                                                {{ formatCurrency(slotProps.data.rate) }}
                                            </template>
                                        </Column>
                                        <Column field="gst" header="Gst">
                                        </Column>
                                        <Column field="sub_total" header="Sub Total" >
                                            <template #body="slotProps">
                                                {{ formatCurrency(slotProps.data.sub_total) }}
                                            </template>
                                        </Column>
                                        <Column header="Delivery To">
                                            <template #body="slotProps">
                                                <span v-if="slotProps.data.location_status==1">
                                                    <Button label="Show" size="small" icon="pi pi-external-link" @click="showLocations(slotProps.data)" />
                                                </span>
                                            </template>
                                        </Column>  
                                    </DataTable>

                                    <!-- Delivery location dilog -->
                                    <Dialog :visible="dialogVisibility" modal :closable="false" :header="dialogHeader" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                                        
                                        <DataTable :value="itemDeliveryLocations"  tableStyle="min-width: 30rem">
                                            <Column field="location" header="Location"></Column>
                                            <Column field="quantity" header="Quantity"></Column>
                                        </DataTable>
                                        <div class="flex justify-content-end flex-wrap">
                                        <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" @click="dialogVisibility=false"></Button>
                                        </div>
                                    </Dialog>
                                    <!-- end delivery location dilog -->                        

                                </div>
                            </template>

                        
                    </DataTable>

                    <Dialog :visible="approveDialogVisibility" modal :closable="false" header="Approve PO" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                        <p>Once approved this PO can't be edited. <br> Are you sure you want to appprove?</p>

                        <div class="flex justify-content-end flex-wrap">
                            <Button class="px-2 my-1 mr-2" label="Confirm" severity="success" @click="approvePo" />
                            <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" @click="approveDialogVisibility=false"></Button>
                        </div>
                    </Dialog>
                </template>
            </Card>
        </div>
    </div>

</template>

<script>
import Card from 'primevue/card'
// import Navbar from '../header/headerNavbar.vue'
import { getOemPoList } from '@/services/apiService';
import { FilterMatchMode } from 'primevue/api'
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Inplace from 'primevue/inplace';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Tag from 'primevue/tag';
// import Calendar from 'primevue/calendar';
// import InputNumber from 'primevue/inputnumber';
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
    components:{
        // Navbar,
        SideBar,
        Card,
        InputText,
        DataTable,
        Column,
        Inplace,
        Button,
        Dialog,
        Tag,
        // Calendar,
        // InputNumber,
        // ConfirmDialog,
    },
    data(){
        return{
            expandedRows:[],
            oemPos:[],
            loadingStatus:false,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                po_no: { value: null, matchMode: FilterMatchMode.IN },
                'manufacturer.name':{ value: null, matchMode: FilterMatchMode.IN },
            },
            dialogHeader:null,
            dialogVisibility:false,
            itemDeliveryLocations:null,
            approveDialogVisibility:false,
            editParentRow:[],
            editChildRow:[],
            oemPoId:null,
        }
    },
    methods:{
        getOemPoList(){
        const query = '?cancel[eq]=1';
        this.loadingStatus = true;
            getOemPoList(query).then((res)=>{
                this.oemPos = res.data;
                this.loadingStatus = false;
            }).catch((error)=>this.showErrorToast(error.response.data));

        },
        showLocations(data){
            this.dialogHeader = data.item_name;
            this.dialogVisibility = true;
            this.itemDeliveryLocations = data.location;
        },     
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        },
    },
    mounted(){
        this.getOemPoList();
    }
}
</script>

<style>

</style>