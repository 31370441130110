   
  <!-- menu -->
  
<template>
        
        <!-- <Menubar :model="items" class="surface-0 w-full"> -->
        <div class=" flex"> 
            <Menubar :model="items" class="fixed-menu">
                <template #start>
                    <a class="inline-flex align-items-center gap-1 " @click="this.$router.push('/dashboard')">
                        <img alt="user header" src="/assets/images/_dashboard_logo.png" height="35px;" class="px-5 mt-2 mb-0 pb-0"/>
                    </a>
                </template>
            
                <template #end>
                    <div class="flex align-items-center gap-2 mr-2"> 
                            <div class="cursor-pointer flex align-items-center justify-content-center mr-3">
                                
                                <i v-badge.success="notificationCount" class="pi pi-bell" style="font-size: 2rem" @click="toggleNotification"/>
                                
                                <!-- overlay panel -->
                                <OverlayPanel ref="notificationOverlayPanel" >

                                    <!-- 2nd design -->
                                    <div class="flex flex-column gap-1 w-25rem max-h-22rem">
                                        <div class="mb-2">
                                            <span class="text-lg" >Notifications</span>
                                            <!-- <Divider /> -->
                                        </div>

                                        <ul class="overflow-auto list-none p-0 m-0 flex flex-column ">

                                                <router-link :to="{
                                                    name: 'notifications',    
                                                 }" v-for="(notification, index) in notificationsList" 
                                                :key="index" class="flex align-items-center cursor-pointer mb-2 " 
                                                style="text-decoration:none" @click="toggleNotification">
                                                <!-- <div> -->
                                                    <i :class="notifIconClass" style="color: slateblue; font-size: 2rem" ></i>
                                                    <div class="w-8 ml-4">
                                                        <span class="font-medium text-base text-900">{{ notification.message.data.title }}</span>
                                                        <div class="text-sm text-900">{{ notification.message.data.message }}</div>
                                                    </div>
                                                    <div class="flex align-items-center text-color-secondary text-sm ml-1 w-2">
                                                        <!-- {{ notification.timeGap }} -->
                                                    </div>
                                                <!-- </div> -->
                                                </router-link> 

                                        </ul>

                                        <div v-if="notificationsList.length==0" class="text-center mb-3">
                                            <label class="text-lg font-medium">No new notifications</label>
                                        </div>

                                        <!-- <Divider align="center"> -->
                                        <div class="flex justify-content-center">
                                            <router-link :to="{
                                                    name: 'notifications',    
                                                 }" @click="toggleNotification">
                                            <Button v-if="notificationsList.length>0" label="View all notifications" link size="small" />
                                            </router-link>
                                            <router-link :to="{
                                                    name: 'notifications',    
                                                 }" @click="toggleNotification">
                                            <Button v-if="notificationsList.length==0" label="Show old notifications" link size="small" />
                                            </router-link>
                                        </div>
                                        <!-- </Divider> -->
                                    </div>
                                    <!-- 2nd design end -->

                                </OverlayPanel>
                                <!-- overlay panel -->
                            </div>
                            <div class="cursor-pointer flex align-items-center justify-content-center">
                                <Avatar type="button" image="/assets/images/logo.png" shape="circle" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" size="large"/>
                            </div>
                            <Menu ref="menu" id="overlay_menu" :model="sideitems" :popup="true"  />
                    </div>
                </template>
            </Menubar>

        </div>
</template>

<script>
import Menubar from 'primevue/menubar';
import Avatar from 'primevue/avatar';
import Menu from 'primevue/menu';
import { logout } from '@/services/apiService';
// import Echo from 'laravel-echo';
import OverlayPanel from 'primevue/overlaypanel';
// import Divider from 'primevue/divider';
import Button from 'primevue/button';
// import OrderList from 'primevue/orderlist';
// import Panel from 'primevue/panel';
import { mapActions, mapWritableState } from 'pinia'
import { useNotificationStore } from '../../stores/notificationStore'

export default {

    components: { 
        Menubar, 
        Avatar, 
        Menu, 
        OverlayPanel,
        // Panel,
        // Divider,
        Button,
        // OrderList,
    },
    data() {
        return {
            items2: [
                // {
                //     label: 'Dashboard',
                //     icon: 'pi pi-home',
                //     command: ()=>{
                //                 this.$router.push('/dashboard')
                //     }
                // },
                {
                    label:'Department',
                    items:[
                            {
                                label: 'Departments List',
                                command: ()=>{
                                            this.$router.push('/departments')
                                }
                            },
                            {
                                label: 'Roc',
                                items: [
                                    {
                                        label: 'New',
                                        icon: 'pi pi-plus',
                                        command: ()=>{
                                            this.$router.push('/add-roc')
                                        }
                                    },
                                    {
                                        label: 'List all',
                                        icon: 'pi pi-server',
                                        command: ()=>{
                                            this.$router.push('/roc-list')
                                        }
                                    },
                                ]
                            },
                            {
                                label: 'Department PO',
                                items: [
                                    {
                                        label: 'New',
                                        icon: 'pi pi-plus',
                                        command: ()=>{
                                            this.$router.push('/add-dept-po')
                                        }
                                    },
                                    {
                                        label: 'List all',
                                        icon: 'pi pi-server',
                                        command: ()=>{
                                            this.$router.push('/dept-po-list')
                                        }
                                    },
                                ]
                            },
                        ]
                },
                {
                    label:'OEM',
                    items:[
                            {
                                label:'OEMs List',
                                command: ()=>{
                                    this.$router.push('/manufacturer-list')
                                }
                            },
                            {
                                label: 'OEM PO',
                                items: [
                                    {
                                        label: 'New',
                                        icon: 'pi pi-plus',
                                        command: ()=>{
                                            this.$router.push('add-oem-po')
                                        }
                                    },
                                    {
                                        label: 'Draft',
                                        icon: 'pi pi-file-edit',
                                        command: ()=>{
                                            this.$router.push('oem-po-drafts')
                                        }
                                    },
                                    {
                                        label: 'Approved',
                                        icon: 'pi pi-check-square',
                                        command: ()=>{
                                            this.$router.push('oem-po-list')
                                        }
                                    },
                                    {
                                        label: 'Cancelled',
                                        icon: 'pi pi-file-excel',
                                        command: ()=>{
                                            this.$router.push('oem-po-cancelled')
                                        }
                                    },
                                ]
                            },
                    ]
                },
                {
                    label:'Delivery Challan',
                    // command:()=>{
                    //                         this.$router.push('/delivery-challan')
                    //                     }
                    items: [
                                    {
                                        label: 'List All',
                                        command:()=>{
                                            this.$router.push('/delivery-challan')
                                        }
                                    },
                                    {
                                        label: 'Bulk Item Delivery Challan',
                                        command:()=>{
                                            this.$router.push('/delivery-challan-bulk')
                                        }
                                    },
                                    {
                                        label: 'Cancelled Challans',
                                        command:()=>{
                                            this.$router.push('/cancelled-delivery-challan')
                                        }
                                    },
                                ]
                },
                {
                    label:'Bill',
                    items: [
                                {
                                    label: 'New',
                                    icon: 'pi pi-plus',
                                    command:()=>{
                                        this.$router.push('/add-new-bill')
                                    }
                                },
                                {
                                    label: 'Draft',
                                    icon: 'pi pi-file-edit',
                                    command:()=>{
                                        this.$router.push('/bill-list-draft')
                                    }
                                },
                                {
                                    label: 'Approved',
                                    icon: 'pi pi-check-square',
                                    command:()=>{
                                        this.$router.push('/bill-list')
                                    }
                                },
                                {
                                    label: 'Cancelled',
                                    icon: 'pi pi-file-excel',
                                    command:()=>{
                                        this.$router.push('/bill-list-cancelled')
                                    }
                                },
                            ]
                },
                {
                    label:'Stock',
                    items: [
                                {
                                    label: 'Stock Arrival',
                                    command:()=>{
                                            this.$router.push('/stock-arrival')
                                    }        
                                },
                                {
                                    label: 'Invoices',
                                    command:()=>{
                                            this.$router.push('/invoice-details')
                                    }        
                                },
                                {
                                    label: 'Store Inventory',
                                    command:()=>{
                                            this.$router.push('/inventory')
                                    }        
                                },
                                {
                                    label: 'Stores',
                                    command:()=>{
                                            this.$router.push('/store-details')
                                    }        
                                },
                        ]
                },
                {
                    label: 'Others',
                    items: [
                                    {
                                        label: 'Terms & Conditions',
                                        command:()=>{
                                            this.$router.push('/terms-and-conditions')
                                        }
                                    },
                                    {
                                        label: 'Categories',
                                        command:()=>{
                                            this.$router.push('/categories')
                                        }
                                    },
                                ]
                },
                {
                    label: 'SideBarTwo',
                    command: ()=>{
                         this.$router.push('/sidebar-two')
                    }
                }
                // {
                //     label: 'Pincode Test',
                //     command: ()=>{
                //          this.$router.push('/pincode')
                //     }
                // }

            ],
            sideitems:[
               {
                label: 'Profile',
                icon:'pi pi-user'
               },
               {
                label:'Logout',
                icon: 'pi pi-sign-out',
                command: () => {
                        logout().then((res)=>{
                            localStorage.clear();
                            this.logoutToast(res.data);
                            this.$router.push('/logout');
                        }).catch((error)=>this.showErrorToast(error.response.data)); 
                }
               }
            ],
            notificationsList:[],
            notificationCount:0,
            notifIconClass:'pi pi-cog',
        };
    },
    computed:{
       ...mapWritableState(useNotificationStore, ['notifications', 'getNotificationCount'])       
    },
    methods:{
        ...mapActions(useNotificationStore, ['$subscribe']),
        toggleNotification(event){         
            this.$refs.notificationOverlayPanel.toggle(event);
        },
        toggle(event){
            this.$refs.menu.toggle(event);
        },
        logoutToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        },
        showErrorToast(error){
               this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 5000 });
        },
    },
    mounted(){
        this.$subscribe((mutation, state)=>{                         
            this.notificationsList = state.notifications             
            this.notificationsList.reverse()
            this.notificationCount = this.getNotificationCount  
            return                   
        })  
        this.notificationsList = this.notifications
        this.notificationCount = this.getNotificationCount 
    },
};
</script>

<style scoped>

.fixed-menu {
    position: fixed;
    height: 53px;
    width: 100%;
    min-width: 100%;
    top: 0;
    z-index: 990;
    /* margin-right: 4rem; */
    /* width: calc(100% - 210px); */
    /* width: calc(100vw - 226px); */
    /* width: calc(100vw - 226px); */
    /* width: 99vw; */
    /* margin-bottom: 10em; */
    margin-bottom: 50px;
    /* margin-left: 210px; */
    /* padding-right: 2rem; */
}

.p-menubar {
    background-color: white;
}


</style>