import axios from "axios";

export async function login(email, password){
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/login?email='+email+'&password='+password);
    return response;
}

export async function logout(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/logout', {data:localStorage.getItem('bearer-token')}, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function getUser(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/users/verify', {data:localStorage.getItem('bearer-token')}, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function dashboardData(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/dashboard', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function placedDeptPo(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/dashboard/dept-po-order-placed', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function deptPartialOrder(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/dashboard/dept-po-partial-placed', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function deptPendingOrder(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/dashboard/dept-po-pending-order', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function oemPoConfirmed(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/dashboard/oem-po-confirmed', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function piRecd(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/dashboard/oem-po-pi-recd', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function piPending(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/dashboard/oem-po-pi-pending', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function createDept(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/departments', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function createCategory(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/categories', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getCategories(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/categories', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function getDepartments(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/departments', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function updateDepartment(data, id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.patch(process.env.VUE_APP_API_URL+'/api/departments/'+id, data, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function createManufacturer(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/manufacturers', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function updateManufacturer(data, id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.patch(process.env.VUE_APP_API_URL+'/api/manufacturers/'+id, data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getManufacturers(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/manufacturers', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function saveManufacturerCategoryMapping(data){
    
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/manufacturer-mapping', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;

}

export async function unmapManufacturer(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/manufacturer-unmapping', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getCategoryMapping(id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/manufacturer-mapping?id='+id, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

// Roc 

export async function createRoc(data){    
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/rocs', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function rocNewItem(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/rocs/item', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getNonRocItems(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/non-roc-items', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function updateRocItem(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/rocs/update-item', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function addItemCMC(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/rocs/item/cmc', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function fetchCMC(id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/rocs/item/cmc/'+id, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export function rocUpload(file){
    console.log(file);
}

export async function getRocList(query){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/rocs'+query, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function getDeptRocList(filteredData){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/rocs'+filteredData, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

// Categories
export async function createSubCategory(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/sub-categories', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}
// Department PO

export async function createDeptPO(data){
    
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/department-po', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getDeptPOList(query){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/department-po'+query, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function deptOrderOnly() {
    
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/department-po-only', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function createDeptPOItemLocation(data){

    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/department-po/item-delivery', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getDeliveryLocations(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/delivery-locations', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function getPendingItemsToOrder(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/department-po/items-pending-order', data, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function getOrderCounter(query){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token');
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/get-order-no', query, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function getLastRate(query){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token');
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/get-last-rate', query, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function createOemPO(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getOemPoList(query){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/oem-po'+query, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function updateOemPo(data, id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.patch(process.env.VUE_APP_API_URL+'/api/oem-po/'+id, data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function updateOemPoItem(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/update-po-item', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function deleteOemPoItem(id){
    
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/delete-item', id, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function deleteOemPo(id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.delete(process.env.VUE_APP_API_URL+'/api/oem-po/'+id, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function approveOemPo(id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/approve', id, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function cancelOemPo(id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/cancel', id, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function savePoPayment(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/payment', data, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function oemItemStatusUpdate(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/item-status-update', data, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function updateOemItemDispatchStatus(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/item-dispatch-update', data, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function updateInTransit(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/item-transit-update', data, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function getPendingOemItem(query){    
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/items-pending', query, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function getStores(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/stores', {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function createStore(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/stores', data, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function updateStore(data, id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.patch(process.env.VUE_APP_API_URL+'/api/stores/'+id, data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function updateStoreStock(data){

    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/update-store-stock', data, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function getTermsAndConditions(query){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/terms-and-conditions/'+query, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function deleteTermAndCondition(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.delete(process.env.VUE_APP_API_URL+'/api/terms-and-conditions/'+data, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

export async function createTermsAndConditions(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/terms-and-conditions', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function updatePIStatus(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/pi-status', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function saveRemark(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/pi-remark', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function confirmOemPo(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/oem-po/pi-confirmed', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function createInvoice(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/invoices', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getInvoices(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/invoices', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function fetchHsnList(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/hsn-list', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getStockArrivals(){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/stock-details', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function storeStock(){   
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/store-stock', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function fetchInvoiceDetails(id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/invoices/invoice-item-details', id, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response;
}

// delivery challan
export async function deliveryChallan(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/delivery-challan', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getDeliveryChallan(query){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/delivery-challan'+query, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function cancelDc(data){   
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/delivery-challan/cancel-dc', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function updateDc(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/delivery-challan/update-dc', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

// bill
export async function createBill(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/bill', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function billList(query){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/bill'+query, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function cancelBill(id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/bill/'+id+'/cancel-bill', id, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

export async function approveBill(id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/bill/approve-bill', id, {
        headers:{
            'Authorization': accessToken
        }
    });
    return response
}

// fetch previous bills for dept po
export async function fetchPrevBill(id){    
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/department-po/previous-bills', id, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function updateGivenDc(data, id){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/delivery-challan/edit', {'id':id, data}, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function updateDcStatusAndStock(data){
    
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/delivery-challan/given-status', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function updateRecdDc(data){
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/delivery-challan/update-received-status', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getItemsPendingDelivery(){ 
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/items-pending-delivery', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getEquipmentInstallations() { 
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/equipment-installations', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function fetchPendingEquipmentsInstallation() {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/equipments-pending-installation', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function equipmentInstallationDashData() {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/equipment-dashboard-data', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function addEquipInstallation(data) {    
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/add-equipment-installation', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function cancelEquipmentInstallation(param) {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/cancel-equipment-installation', param, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function deleteEquipmentInstallation(id) {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.delete(process.env.VUE_APP_API_URL+'/api/delete-equipment-installation/'+id, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function fetchEquipmentManufacturers() {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/equipment-manufacturer', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function fetchInstallationEngineers() {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/installation-engineer', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getEquipmentDetails(id) {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/equipment-details/'+id, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function addEquipmentCmc(data) {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/equipment-cmc', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getEquipmentCmc(id) {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/equipment-cmc/'+id, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getEquipmentCancelledCmcs(id) {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/equipment-cmc-cancelled/'+id, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function cancelServicing(data, id) {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/cancel-equipment-cmc/'+id, data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getInstalledEquipsServices() {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/installed-equipment-cmcs', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getInstalledEquipmentDetails(id) {    
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/installed-equipment-details/'+id, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function createCmcBill(data) {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/create-cmc-bill', data, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getCmcBills() {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/cmc-bills', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function getCmcDetails(id) {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/cmc-bill-details/'+id, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

// reports

export async function deptOrderItemsList(){ 
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/dept-order-items', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function oemOrderedItemsList(){ 
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/oem-ordered-items', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function notifications() {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.get(process.env.VUE_APP_API_URL+'/api/notifications', {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

export async function markAllReadCmcNotifications() {
    const accessToken = 'Bearer '+localStorage.getItem('bearer-token')
    const response = await axios.post(process.env.VUE_APP_API_URL+'/api/notifications-mark-all-read', null, {
        headers:{
            'Authorization': accessToken
        },
    });
    return response;
}

// pincode api
export async function getPincodeDetails(data){
    const options = {
        method: 'GET',
        url: 'https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/'+data,
        headers: {
          'X-RapidAPI-Key': 'a1e841d516msh08eb46dc97d3b13p1d020fjsne2452e2d5eb3',
          'X-RapidAPI-Host': 'india-pincode-with-latitude-and-longitude.p.rapidapi.com'
        }
      };

      try {
            const response = await axios.request(options);
            return response
        } catch (error) {
            console.error(error);
        }
}