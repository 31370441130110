<template>
<!-- <Navbar /> -->
<!-- <SideBar/>  -->
  
    <div class="grid main-content">    

        <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
        <!-- UI 3 trial -->

        <div class="col-12 md:col-12 lg:col-12 ">
                <div class="flex mt-2">
                    <div class="flex w-full font-medium text-xl ml-2">
                        Company Dashboard
                    </div>
                </div>
            </div>
        
        <div class="sm:col-12 md:col-7 lg:col-7">
            <div class="flex">
                    <div class="flex  w-full mx-4 mt-2">
                        <div class="surface-card shadow-2 p-3 border-round-lg  w-full ">
                            <div class="flex mt-2">
                                <div class="flex align-items-center font-medium text-lg my-2 mx-2 w-full">                                        
                                            Department Orders Summary                                        
                                </div>
                                <div class="flex align-items-center justify-content-end mx-2">
                                    <label class="font-medium text-xl">
                                        <Badge severity="success" size="large"> 
                                            {{totalDeptPo}}
                                        </Badge>                        
                                    </label>
                                </div>
                            </div>

                            <div class="flex pt-2">
                                <div class="flex align-items-center  w-10 mx-3">
                                    <i class="pi pi-plus-circle mr-2 bg-blue-100 border-round-lg px-2 py-2"></i>
                                    <div class="field ">
                                        <div class="text-base font-medium ml-2 mt-2">
                                            <router-link to="#" @click="showDeptPoDialog(1, dashData.deptPo.dailyDeptPo)">
                                            New Orders received
                                            </router-link>
                                            </div>
                                        <div class="text-sm font-medium ml-2 text-gray-400"> As On {{todayDate}}</div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center justify-content-end w-2 mx-4">
                                    <label class="text-lg">
                                        <strong>
                                        {{dailyDeptOrdersCount}}
                                        </strong>
                                    </label>
                                </div>
                            </div>
                            <hr class="hr-style">
                            <div class="flex ">
                                <div class="flex align-items-center  w-10 mx-3">
                                    <i class="pi pi-check-square mr-2 bg-green-100 border-round-lg px-2 py-2"></i>
                                    <div class="field ">
                                        <div class="text-base font-medium ml-2 mt-2">Orders completed</div>
                                        <div class="text-sm font-medium ml-2 text-gray-400"> Billed</div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center justify-content-end w-2 mx-4">
                                    <label class="text-lg">
                                        <strong>
                                        {{deptPoCompleted}}
                                        </strong>
                                    </label>
                                    </div>
                            </div>
                            <hr class="hr-style">
                            <div class="flex ">
                                <div class="flex align-items-center  w-10 mx-3">
                                    <i class="pi pi-check mr-2 bg-orange-100 border-round-lg px-2 py-2"></i>
                                    <div class="field ">
                                        <div class="text-base font-medium ml-2 mt-2">Orders partially completed</div>
                                        <div class="text-sm font-medium ml-2 text-gray-400"> Partially billed</div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center justify-content-end w-2 mx-4">
                                    <label class="text-lg">
                                        <strong>
                                        {{deptPoPartialCompleted}}
                                        </strong>
                                    </label>
                                </div>
                            </div>
                            <hr class="hr-style">
                            <div class="flex ">
                                <div class="flex align-items-center  w-10 mx-3">
                                    <i class="pi pi-hourglass mr-2 bg-yellow-100 border-round-lg px-2 py-2"></i>
                                    <div class="field ">
                                        <div class="text-base font-medium ml-2 mt-2">Orders pending completion</div>
                                        <div class="text-sm font-medium ml-2 text-gray-400"> Not billed till date</div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center justify-content-end w-2 mx-4">
                                    <label class="text-lg">
                                        <strong>
                                        {{deptPoPending}}
                                        </strong>
                                    </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex  w-full ml-2 mt-2">
                        <div class="surface-card shadow-2 p-3 border-round-lg  w-full ">
                            <div class="flex mt-2">
                                <div class="flex align-items-center font-medium text-lg w-full my-2 mx-2">
                                        OEM Orders Summary
                                </div>
                                <div class="flex align-items-center mx-2">
                                    <label class="font-medium text-xl">
                                        <Badge severity="secondary" size="large"> 
                                            {{totalOemPo}}
                                        </Badge>
                                    </label>
                                </div>
                            </div>

                            <div class="flex pt-2">
                                <div class="flex align-items-center  w-10 mx-3">
                                    <router-link to="/oem-po-drafts" >
                                    <i class="pi pi-file-edit mr-2 bg-orange-100 border-round-lg px-2 py-2"></i>
                                    </router-link>
                                    <div class="field ">
                                        <div class="text-base font-medium ml-2 mt-4"> 
                                            <router-link to="/oem-po-drafts" >Orders in draft</router-link>
                                            
                                            </div>
                                        <div class="text-sm font-medium ml-2 text-gray-400"> Pending to be approved</div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center justify-content-end w-2 mx-4">
                                    <label class="text-lg">
                                        <strong>
                                            {{oemPoDraft}}
                                        </strong>
                                    </label>
                                </div>
                            </div>

                            <hr class="hr-style">

                            <div class="flex pt-2">
                                <div class="flex align-items-center  w-10 mx-3">
                                    <i class="pi pi-check-square mr-2 bg-green-100 border-round-lg px-2 py-2"></i>
                                    <div class="field ">
                                        <div class="text-base font-medium ml-2 mt-2">Orders approved today</div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center justify-content-end w-2 mx-4">
                                    <label class="text-lg">
                                        <strong>
                                            {{oemPoApprovedToday}}
                                        </strong>
                                    </label>
                                </div>
                            </div>

                            <hr class="hr-style">

                            <div class="flex pt-2">
                                <div class="flex align-items-center  w-10 mx-3">
                                    <i class="pi pi-hourglass mr-2 bg-yellow-100 border-round-lg px-2 py-2"></i>
                                    <div class="field ">
                                        <div class="text-base font-medium ml-2 mt-2">
                                            <router-link to="#" @click="showOemPoDialog(1, dashData.oemPo.oemPosPendingPi)">
                                            Orders with pending PI
                                            </router-link>
                                            </div>
                                        <div class="text-sm font-medium ml-2 text-gray-400"> PI confirmation pending</div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center justify-content-end w-2 mx-4">
                                    <label class="text-lg">
                                        <strong>
                                            {{oemPoPiPending}}
                                        </strong>
                                    </label>
                                </div>
                            </div>

                            <hr class="hr-style">

                            <div class="flex pt-2">
                                <div class="flex align-items-center  w-10 mx-3">
                                    <i class="pi pi-money-bill mr-2 bg-yellow-100 border-round-lg px-2 py-2"></i>
                                    <div class="field ">
                                        <div class="text-base font-medium ml-2 mt-2">
                                            <router-link to="#" @click="showOemPoDialog(2, dashData.oemPo.oemPosPendingPayment)">
                                            Orders with pending payments
                                            </router-link>
                                            </div>
                                        <div class="text-sm font-medium ml-2 text-gray-400"> Payments to be made to OEMs</div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center justify-content-end w-2 mx-4">
                                    <label class="text-lg">
                                        <strong>
                                            {{oemPoPendingPayment}}
                                        </strong>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
            </div>
            <div class="flex">
                    <div class="flex  w-full ml-4 mt-5 ">
                        <div class="surface-card shadow-2 p-3 border-round-lg  w-full ">
                            <div class="flex mt-2 mx-3">
                                <div class="flex align-items-center font-medium w-full text-lg my-2">
                                        Delivery Challans Summary
                                </div>
                                <div class="flex align-items-center ">
                                   <label class="font-medium text-xl">
                                        <Badge severity="success" size="large"> 
                                            {{totatDc}}
                                        </Badge>
                                    </label>                                        
                                </div>
                            </div>

                            <div class="flex  mx-3">
                                <div class="flex align-items-center  w-8">
                                    <div class="field ">
                                        <div class="text-base font-medium  ml-2 ">
                                            
                                            <Button severity="info" link @click="showDialog(1, dashData.deliveryChallan.pendingRecdDcs)">
                                                Pending to receive
                                            </Button>
                                            </div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center justify-content-end w-4 mr-3">
                                    <i class="pi pi-sign-in mr-2  px-2 py-2" style="color:#842ddb"></i>
                                    <label class="text-lg">
                                        {{dcPendingRecdCount}}
                                    </label>
                                </div>
                                <div class="flex align-items-center justify-content-end w-4 mr-3">
                                    <label class="text-lg">
                                        {{formatCurrency(dcPendingRecdValue)}}
                                    </label>
                                </div>
                            </div>

                            <div class="flex  mx-3">
                                <div class="flex align-items-center  w-8">
                                    <div class="field ">
                                        <div class="text-base font-medium  ml-2 ">
                                            <Button link severity="danger" @click="showDialog(2, dashData.deliveryChallan.pendingDcGiveOut)">
                                                Challan pending to give out
                                            </Button>
                                        </div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center justify-content-end w-4 mr-3">
                                    <i class="pi pi-sign-out mr-2 px-2 py-2" style="color:red"></i>
                                    <label class="text-lg">
                                        {{dcPendingGiveOutCount}}
                                    </label>
                                </div>
                                <div class="flex align-items-center justify-content-end w-4 mr-3">
                                    <label class="text-lg ">
                                        {{formatCurrency(dcPendingGiveOutValue)}}
                                    </label>
                                </div>
                            </div>

                            <div class="flex  mx-3">
                                <div class="flex align-items-center  w-8">
                                    <div class="field ">
                                        <!-- <div class="text-base font-medium  ml-2 mt-2">Challan generated today ({{todayDate}})</div> -->
                                        <div class="text-base font-medium  ml-2 ">
                                            <Button link severity="success" @click="showDialog(3, dashData.deliveryChallan.dailyDc)">
                                                Challan generated today ({{todayDate}})
                                            </Button>
                                        </div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center justify-content-end w-4 mr-3">
                                    <i class="pi pi-pencil mr-2 px-2 py-2" style="color:#3dce1d"></i>
                                    <label class="text-lg">
                                        {{dcGeneratedTodayCount}}
                                    </label>
                                </div>
                                <div class="flex align-items-center justify-content-end w-4 mr-3">
                                    <label class="text-lg">
                                        {{formatCurrency(dcGeneratedTodayValue)}}
                                    </label>
                                </div>
                            </div>
                            
                        </div>
                    </div>
            </div>
        </div>


        <!-- billing card -->
        <div class="sm:col-12 md:col-5 lg:col-5">
            <div class="flex">
                    <div class="flex align-items-center w-full mt-2 ml-4">
                        <div class="surface-card shadow-2 p-3 border-round-lg w-full mr-3">
                            <div class="flex my-2">
                                <div class="flex align-items-center font-medium text-lg mt-2 mb-1 ml-3">
                                        Billing Statistics
                                </div>
                                <div class="flex align-items-center w-2">                                    

                                </div>
                            </div>
                            <div class="flex justify-content-end my-2">
                                <div class="flex align-items-center mx-3">
                                    <label class="font-medium text-2xl text-green-600">
                                        {{formatCurrency(totalBilledValue)}} 
                                    </label>
                                </div>
                            </div>
                            <div class="flex justify-content-end">
                                <div class="flex align-items-center mx-3">
                                    <label class="font-medium text-base text-400">
                                        Total billed value 
                                    </label>
                                </div>
                            </div>
                            <div class="flex pt-2 w-full my-2 ">
                                <div class="flex align-items-center w-full">
                                    <Chart type="bar" :data="chartData" :options="chartOptions" class="h-30rem pb-2 pl-5" width="550" />                                   
                                </div>                                
                            </div>
                            <div class="flex mb-3">
                                <div class="flex align-items-center  w-full justify-content-center">
                                    <label class="text-400 font-medium">
                                        FY: {{fy}}
                                    </label>
                                </div>
                            </div>
                            
                        </div>
                    </div>
            </div>
        </div>


       <!-- dialog box -->
    <Dialog :visible="dialogVisibility"  :modal="true" :style="{ width: auto }" :closable="false" :dismissableMask="true">

        <template #header >
            <div class="flex justify-content-between flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl font-semibold">
                    {{dialogHeader}}
                </div>
                <div class="flex align-items-center justify-content-center">
                    
                    <Button icon="pi pi-external-link" label="Export" size="small" text raised class="ml-7 mr-2" @click="exportData($event)" v-tooltip.top="'Export CSV'"/>
                    <InputText v-model="filterQuery" placeholder="Keyword Search" size="small"/>
                    <Button class="ml-2" icon="pi pi-filter" severity="success" raised size="small" @click="globalFilter" v-tooltip.top="'Search'"/>
                    <Button class="ml-2" icon="pi pi-filter-slash" size="small" text raised severity="success" @click="clearFilter" v-tooltip.top="'Clear result'"/>
                    <Button class="ml-8" label="Close" text raised severity="secondary" @click="dialogVisibility=false"/>
                </div>
                
            </div>
        </template>

            <DataTable ref="dcTable" :value="tableData" :size="small" :loading="loadingStatus"  paginator :rows="10" :rowsPerPageOptions="[10, 20, 50, 100]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id" >
                <Column expander style="width: 0.5rem"/>
                <Column field="dcNo" header="DC No."></Column>
                <Column field="dcDate" header="Date"></Column>
                <Column field="deptPo" header="Dept PO"></Column>
                <Column field="location.name" header="Location"></Column>

                <template #expansion="slotProps">
                    <DataTable :value="slotProps.data.dcItems">
                    <Column field="name" header="Item"/>
                    <Column field="hsn" header="HSN"/>
                    <Column field="packing" header="Packing"/>
                    <Column field="qty" header="Quantity"/>
                    <Column field="modelNo" header="Model" />
                    <Column field="partNo" header="Part/Sr No." />
                    <Column field="batch_no" header="Batch" />
                    <Column field="mfgDate" header="Mfg/Exp Date">
                        <template #body="slotProps">
                                {{ slotProps.data.mfgDate }}/{{slotProps.data.expDate}}
                            </template>
                    </Column>
                    </DataTable>
                </template>
            </DataTable>
    </Dialog>
       <!-- dialog box end -->


        <!-- oem po dialog -->
        <Dialog :visible="oemDialogVisibility"  :modal="true" :style="{ width: auto }" :closable="false" :dismissableMask="true">

            <template #header >
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex align-items-center justify-content-center text-xl font-semibold">
                        {{oemPoDialogHeader}}
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        
                         <Button icon="pi pi-external-link" label="Export" size="small" text raised class="ml-7 mr-2" @click="exporOemTabletData($event)" v-tooltip.top="'Export CSV'"/>

                         <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        <Button class="ml-8" label="Close" text raised severity="secondary" @click="oemDialogVisibility=false"/>
                    </div>
                    
                </div>
            </template>

                <DataTable ref="oemPoTable" :value="oemPoData" :size="small" :loading="loadingStatus" v-model:filters="filters" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50, 100]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id" >
                    <Column expander style="width: 0.5rem"/>
                    <Column field="po_no" header="PO No."></Column>
                    <Column field="po_date" header="PO Date"></Column>
                    <Column field="manufacturer.name" header="OEM"></Column>

                    <template #expansion="slotProps">
                        <DataTable :value="slotProps.data.oem_po_details">
                        <Column field="item_name" header="Item"/>
                        <Column field="packing" header="Packing"/>
                        <Column field="nop" header="NOP" />
                        <Column field="quantity" header="Quantity"/>
                        <Column field="rate" header="Rate" />
                        <Column field="gst" header="GST(%)" />
                        </DataTable>
                    </template>
                </DataTable>

        </Dialog>
        <!-- oem po dialog end -->

        <!-- dept po dialog -->
        <Dialog :visible="deptPoDialogVisibility"  :modal="true" :style="{ width: auto }" :closable="false" :dismissableMask="true">

            <template #header >
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex align-items-center justify-content-center text-xl font-semibold">
                        {{deptPoDialogHeader}}
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        
                         <Button icon="pi pi-external-link" label="Export" size="small" text raised class="ml-7 mr-2" @click="exporDeptPoTabletData($event)" v-tooltip.top="'Export CSV'"/>

                         <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        <Button class="ml-8" label="Close" text raised severity="secondary" @click="deptPoDialogVisibility=false"/>
                    </div>
                    
                </div>
            </template>

                <DataTable ref="deptPoTable" :value="deptPoData" :size="small" :loading="loadingStatus" v-model:filters="filters" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50, 100]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id" >
                    <Column expander style="width: 0.5rem"/>
                    <Column field="po_no" header="PO No."></Column>
                    <Column field="poDate" header="PO Date"></Column>
                    <Column field="po_value" header="Value">
                        <template #body="slotProps">
                            {{ formatCurrency(slotProps.data.po_value) }}
                        </template>
                    </Column>

                    <!-- <template #expansion="slotProps">
                        <DataTable :value="slotProps.data.oem_po_details">
                        <Column field="item_name" header="Item"/>
                        <Column field="packing" header="Packing"/>
                        <Column field="nop" header="NOP" />
                        <Column field="quantity" header="Quantity"/>
                        <Column field="rate" header="Rate" />
                        <Column field="gst" header="GST(%)" />
                        </DataTable>
                    </template> -->
                </DataTable>
        </Dialog>
        <!-- dept po dialog end -->

        <!-- UI 3 end -->

    </div>

 
</template>

<script>
import { dashboardData } from '@/services/apiService'
// import Navbar from '../header/headerNavbar.vue'  
// import SideBar from '../sidebar/sideBarTwo.vue'
import Badge from 'primevue/badge'
import Chart from 'primevue/chart'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import ProgressSpinner from 'primevue/progressspinner'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import { FilterMatchMode } from 'primevue/api'

export default {
    components:{ 
        // Navbar,
        // SideBar,
        Badge,
        Chart,
        Dialog,
        Button,
        DataTable,
        ProgressSpinner,
        Column,
        InputText,
    },
    
    data(){
        return{

            dashData:null,
            spinnerLoadingStatus:false,
            totalDeptPo:0,
            dailyDeptOrder:0,
            deptPoCompleted:0,
            deptPoPartialCompleted:0,
            deptPoPending:0,
            deptPoDialogVisibility:false,
            deptPoDialogHeader:'Department Orders',
            deptPoData:[],

            totalOemPo:0,
            oemPoDraft:0,
            oemPoApprovedToday:0,
            oemPoPiPending:0,
            oemPoPendingPayment:0,
            oemDialogVisibility:false,
            oemPoDialogHeader:null,
            oemPoData:[],

            totatDc:0,
            dcPendingGiveOutCount:0,
            dcPendingGiveOutValue:0,
            dcPendingRecdCount:0,
            dcPendingRecdValue:0,
            dcGeneratedTodayCount:0,
            dcGeneratedTodayValue:0,

            billGraphData:[],
            billGraphLabel:[],
            totalBilledValue:0,

            tableHeader:'Data Header',
            dashItem:[],
            dataCol:[],
            chartData:null,
            chartOptions:null,
            fy:null,
            todayDate:null,
            dialogVisibility:false,
            dialogHeader:'Dialog header',
            tableData:[],
            tempTableData:[],
            filteredDcList:[],
            expandedRows:[],

            filterQuery:null,

            loadingStatus:false,

            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                po_no: { value: null, matchMode: FilterMatchMode.IN },
                'manufacturer.name': { value: null, matchMode: FilterMatchMode.IN }, 
            },
        }
    },

    methods:{
        getDashboardDatta(){
            this.spinnerLoadingStatus = true;
            dashboardData().then((res)=>{
                this.dashData = res.data.data;
                this.totalDeptPo = this.dashData.deptPo.deptPoCount;
                this.dailyDeptOrdersCount = this.dashData.deptPo.dailyDeptOrdersCount;
                this.deptPoCompleted = this.dashData.deptPo.deptPoCompletedCount;
                this.deptPoPartialCompleted = this.dashData.deptPo.deptPoPartialCompletedCount;

                this.totalOemPo = this.dashData.oemPo.totalOemPoCount;
                this.oemPoDraft = this.dashData.oemPo.draftOemPoCount;
                this.oemPoPiPending = this.dashData.oemPo.oemPoPendingPiCount;
                this.oemPoPendingPayment = this.dashData.oemPo.oemPoPendingPaymentCount;
                
                this.totatDc = this.dashData.deliveryChallan.totalDcs;
                this.dcPendingRecdCount = this.dashData.deliveryChallan.pendingRecdDcsCount;
                this.dcPendingGiveOutCount = this.dashData.deliveryChallan.pendingDcGiveOutCount;
                this.dcGeneratedTodayCount = this.dashData.deliveryChallan.dailyDcCount;

                if(this.dashData.deliveryChallan.pendingRecdDcs.length > 0){
                    this.dashData.deliveryChallan.pendingRecdDcs.forEach(e => {
                        e.dcItems.forEach(l => {
                            this.dcPendingRecdValue += (l.deptRate * l.qty) + (((l.deptRate * l.qty)/100)*l.gstRate);
                        });                    
                    });
                }

                if(this.dashData.deliveryChallan.pendingDcGiveOut.length > 0){
                    this.dashData.deliveryChallan.pendingDcGiveOut.forEach(e => {
                        e.dcItems.forEach(l => {
                            this.dcPendingGiveOutValue += (l.deptRate * l.qty) + (((l.deptRate * l.qty)/100)*l.gstRate);
                        });                    
                    });
                }

                if(this.dashData.deliveryChallan.dailyDc.length > 0){
                    this.dashData.deliveryChallan.dailyDc.forEach(e => {
                        e.dcItems.forEach(l => {
                            this.dcGeneratedTodayValue += (l.deptRate * l.qty) + (((l.deptRate * l.qty)/100)*l.gstRate);
                        });                    
                    });
                }
                
                this.todayDate = new Date();                
                this.todayDate = this.todayDate.getDate()+'-'+(this.todayDate.getMonth()+1)+'-'+this.todayDate.getFullYear();

                let flag = 0;



                for (let month = 1; month <= 12; month++) {
                    flag = 0;
                    this.dashData.bill.every(e => {
                        if(e.month == month){                            
                            this.billGraphData.push(e.total);
                            this.totalBilledValue += e.total;
                            flag = 1;
                            return false;
                        } 
                        return true;
                    });
                    if(flag == 0){
                        this.billGraphData.push(0);
                    }
                }    
                
                this.chartData = this.setChartData();
                this.chartOptions = this.setChartOptions();

                this.spinnerLoadingStatus = false;

            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        setChartData() {
            const documentStyle = getComputedStyle(document.documentElement);

            return {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        label: 'Monthly billed value',
                        backgroundColor: documentStyle.getPropertyValue('--cyan-500'),
                        borderColor: documentStyle.getPropertyValue('--cyan-500'),
                        data: this.billGraphData,
                    },
                ]
            };
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

            return {
                responsive:true,
                maintainAspectRatio: false,
                aspectRatio: 2,
                plugins: {
                    legend: {
                        labels: {
                            color: textColor
                        }
                    }
                },
                scales: {
                    x: {
                        title:{
                            display:false,
                            text:'Months',
                        },
                        ticks: {
                            color: textColorSecondary,
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: true
                        }
                    },
                    y: {
                        // title:{
                        //     display:true,
                        //     text:'Figures in Crores',
                        //     color:'#0b4c68',
                        // },
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder,
                            drawBorder: false
                        }
                    }
                },
                locale:"en-IN",
            };
        },
        showDialog(option, data){

            this.tableData = data;
            
            if(option == 1){
                this.dialogHeader = "DC:Pending to receive";
                this.dialogVisibility = true;
            }
            if(option == 2){
                this.dialogHeader = "DC:Pending to give out";
                this.dialogVisibility = true;
            }
            if(option == 3){
                this.dialogHeader = "DC:Challans generated today";
                this.dialogVisibility = true;
            }
        },
        exportData(){
            this.$refs.dcTable.exportCSV();
        },
        exporOemTabletData(){
            this.$refs.oemPoTable.exportCSV();
        },
        showDeptPoDialog(option, data){
            if(option == 1){
                this.deptPoDialogVisibility = true;
                this.deptPoDialogHeader = "New orders received";
                this.deptPoData = data;
            }
        },
        showOemPoDialog(option, data){
           
            if(option == 1){
                this.oemPoDialogHeader = "OEM PO:Orders pending PI";
                this.oemPoData = data;
                this.oemDialogVisibility = true;
            }

            if(option == 2){
                this.oemPoDialogHeader = "OEM Orders pending to receive payment";
                this.oemPoData = data;
                this.oemDialogVisibility = true;
            }
        },
        globalFilter(){

            if(this.filterQuery){
                this.tempTableData = this.tableData;            
                this.filteredDcList = [];
                if(this.filterQuery!=null){
                    this.tableData.forEach(e => {     
                        if (e.dcNo.includes(this.filterQuery) || e.deptPo.includes(this.filterQuery) || e.dept.name.includes(this.filterQuery) || e.location.name.includes(this.filterQuery) || e.billStatus.includes(this.filterQuery)) { 
                          this.filteredDcList.push(e); 
                        }
                        else{
                            e.dcItems.every(el => { 
                                if(el.name.includes(this.filterQuery) || (el.batch_no ? el.batch_no.includes(this.filterQuery) : false ) || (el.partNo ? el.partNo.includes(this.filterQuery) : false) || (el.modelNo ? el.modelNo.includes(this.filterQuery) : false)){
                                    this.filteredDcList.push(e);
                                    return false;
                                }
                                return true;
                            });
                        }
                    });
                }
                this.tableData = this.filteredDcList;

            }


        },
        clearFilter(){
            if(this.filterQuery){
                this.tableData = this.tempTableData;
                this.filterQuery = null;
            }
        },
        formatCurrency(value) {
                return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 4000 });
        },
    },
    mounted(){
        this.getDashboardDatta();

        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth()+1;
        if(month < 4){
            this.fy = (year-1)+'-'+year;
        }
        if(month >= 4){
            this.fy = year+'-'+(year+1);
        }        

    },
}

</script>

<style>

.hr-style{
    border: 0.1rem dashed #eeeeee;
}

</style>