<template>
<Navbar/>
<SideBar/>

<div class="card main-content">
        <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
        <div class="surface-200">
            <Card>
                <template #title>

                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex align-items-center justify-content-center">
                            Stock Arrival Details
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </div>
                    </div>
                </template>
                <template #content>
                    <DataTable :value="stockArr" sortMode="multiple" size="small" v-model:filters="filters" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id"
                  @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" >

                  <Column header="Sl No." >
                    <template #body="slotProps">
                         {{ ++slotProps.index }}
                    </template>
                  </Column>
                  <Column field="name" header="Item"/>
                  <Column field="date" header="Date" sortable />
                  <Column field="quantity" header="Quantity"/>
                  <Column field="oemPo" header="OEM PO"/>
                  <Column field="deliveryLocation" header="Delivered"/>
                  <Column header="Invoice">

                    <template #body="slotProps">
                        <Tag v-if="slotProps.data.invoiceStatus==0" class="px-4" value="Pending" severity="danger" />
                        <label v-if="slotProps.data.invoiceStatus==1">{{slotProps.data.invoiceNo}}</label>
                        <!-- <label v-if="slotProps.data.invoiceStatus==0">Pending</label> -->
                    </template>
                  </Column>
                  <Column header="DC">
                    <template #body="slotProps">
                        <Button v-if="slotProps.data.invoiceStatus==1 && slotProps.data.stockPendingStatus!=1" label="Generate DC" severity="success" size="small" class="px-2 py-2" @click="showDcDialog(slotProps.data)"/>
                        <label v-if="slotProps.data.invoiceStatus==0">Update Invoice</label>
                        <div v-if="slotProps.data.invoiceStatus==1 && slotProps.data.stockPendingStatus!=0">
                            <label v-for="(item, index) in slotProps.data.dcList" :key="index">
                                {{ item.dcNo }} <br>
                            </label>
                        </div>
                    </template>
                  </Column>
                  
                    </DataTable>

                    <Dialog v-model:visible="dcDialogVisibility" modal :closable="false" :header="dcDialogHeader" :style="{ width: '60rem' }">
                        <template #header>
                            <div class="inline-flex align-items-center justify-content-between gap-2">
                                <span class="font-bold white-space-nowrap">DC Details: {{dcDialogHeader}}</span>
                            </div>
                        </template>

                        <div class="formgrid grid">
                            <div class="field col">
                                <label ><strong>Department</strong></label>
                                <Dropdown v-model="dcDept" filter :options="departments" size="small" class="w-full" optionValue="id" optionLabel="name" placeholder="- - Select Dept - -"/>
                            </div> 
                            <div class="field col">
                                <label ><strong>Date</strong></label>
                                <Calendar v-model="dcDate" showIcon class="w-full" dateFormat="dd/mm/yy"/>
                            </div>                            
                        </div>

                        <div class="formgrid grid">
                            <div class="field col">
                                <label><strong>Delivery Location</strong></label>
                                <Dropdown size="small" class="w-full" v-model="selectedDeliveryLocation" filter :options="itemDeliveryLocations" optionLabel="location" placeholder="- - Select Location - -"/>
                            </div>
                            <div class="field col">
                                <label><strong>Item Details</strong></label>
                                <Dropdown size="small" class="w-full" v-model="selectedInvoiceItem" filter :options="itemVariation" optionLabel="name2" placeholder="- - Select Item - -" @change="addItem"/>
                            </div>
                        </div>
                        <!-- <div class="formgrid grid">
                            <div class="field col">
                                <label><strong>DEPT PO Ref.:</strong></label>
                                <div class="col text-green-500">
                                    <strong>{{deptPoNo}}</strong>
                                </div>
                            </div>
                            <div class="field col">
                            </div>
                        </div> -->
                        <div class="field grid">
                            <label class="col-fixed" ><strong><strong>DEPT PO Ref.:</strong></strong></label>
                            <div class="col text-green-500">
                                <strong>{{deptPoNo}}</strong>
                            </div>
                        </div>

                        <hr>

                        <div class="card flex flex-wrap p-fluid">
                            <div v-for="(item, index) in dcItems" :key="index" class="grid">
                                <div class="col">
                                    <label>HSN</label>
                                    <InputText type="text" v-model="item.hsn" variant="filled" readonly>
                                    </InputText>
                                </div>
                                <div class="col">
                                    <label>UOM</label>
                                    <InputText type="text" v-model="item.packing" variant="filled"/>
                                </div>
                                <div class="col">
                                    <label>Batch</label>
                                    <InputText type="text" v-model="item.batchNo" variant="filled" />
                                </div>
                                <div class="col">
                                    <label>Mfg/Exp</label>
                                    <InputText type="text" v-model="item.mfgExp" variant="filled" readonly/>
                                </div>
                                <div class="col">
                                    <label>Part No</label>
                                    <InputText type="text" v-model="item.partNo" variant="filled" />
                                </div>
                                <div class="col">
                                    <label>Model No</label>
                                    <InputText type="text" v-model="item.modelNo" variant="filled" />
                                </div>
                                <div class="col">
                                    <label>Quantity</label>
                                    <InputNumber v-model="item.availableQty" variant="filled" @input="updateAvailable($event, index)"/>
                                </div>
                                <div class="col">
                                    <label>Available</label>
                                    <InputNumber v-model="item.avalQty" variant="filled" readonly/>
                                </div>
                                <div class="col">
                                    <label>Del</label><br>
                                    <Button icon="pi pi-trash" size="small" severity="danger" @click="deleteDcItems(index)"/>
                                </div>
                            </div>
                        </div>  
                        
                        <!-- <div v-if="dcItems.length>0" class="card flex flex-wrap p-fluid">
                            <label class="text-green-500">{{totalDcQty}}</label> 
                        </div> -->

                        <template #footer>
                            <Button label="Cancel" size="small" severity="danger" @click="hideDcDialog" autofocus />
                            <Button label="Generate" size="small" severity="success" @click="saveDeliveryChallan" autofocus />
                        </template>
                    </Dialog>

                </template>
            </Card>
        </div>
</div>

</template>

<script>
// import TabView from 'primevue/tabview'
import Card from 'primevue/card'
// import Navbar from '../header/headerNavbar.vue'
import DataTable from 'primevue/datatable'
import { getStockArrivals, fetchInvoiceDetails, getDepartments, deliveryChallan } from '@/services/apiService'
import ProgressSpinner from 'primevue/progressspinner'
import Column from 'primevue/column'
import Tag from 'primevue/tag'
import { FilterMatchMode } from 'primevue/api'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import InputNumber from 'primevue/inputnumber'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
    
    components:{
        // Navbar,
        SideBar,
        Card,
        DataTable,
        ProgressSpinner,
        Column,
        Tag,
        InputText,
        Button,
        Dialog,
        Calendar,
        Dropdown,
        InputNumber,
    },
    data(){
        return{
            expandedRows:[],
            stockArr:[],
            spinnerLoadingStatus:false,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.IN },
                oemPo: { value: null, matchMode: FilterMatchMode.IN },          
                deliveryLocation: { value: null, matchMode: FilterMatchMode.IN },
            },
            dcDialogHeader:null,
            dcDialogVisibility:false,
            dcDept:null,
            dcDate:null,
            invoiceItems:null,
            selectedInvoiceItem:null,
            selectedDeliveryLocation:null,
            deliveryLocations:null,
            deptPo:null,
            deptPoNo:null,
            itemVariation:[],
            itemDeliveryLocations:[],
            dcItems:[],
            departments:[],
            dcNo:null,
            // totalDcQty:0,
        }
    },
    methods:{
        getStockList(){
            this.spinnerLoadingStatus = true;
            getStockArrivals().then((res)=>{
                this.stockArr = res.data;
                this.spinnerLoadingStatus = false;
            }).catch((error)=>{
                this.spinnerLoadingStatus = false;
                this.showErrorToast(error.response.data);
            })
        },
        getDepts(){
            getDepartments().then((res)=>{
                this.departments = res.data;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
            })
        },
       
        showDcDialog(data){
            
            this.spinnerLoadingStatus = true;
            fetchInvoiceDetails({id:data.id}).then((res)=>{
                
                this.getDepts();

                this.itemVariation = [];
                res.data.invoiceItem.forEach(e => {
                    let mfgExp = null;
                    if(e.mfgDate || e.expDate){
                        mfgExp = e.mfgDate+'/'+e.expDate;
                    }
                    
                    if(e.availableQty > 0){
                        this.itemVariation.push ({
                            ...e,
                            name2:e.name+'('+e.quantity+')',
                            mfgExp,
                            avalQty:e.availableQty,
                            totalQty:e.quantity,
                        });

                    }
                    this.dcDialogHeader = e.name;
                });

                this.itemDeliveryLocations = [];
                
                res.data.deliveryLocation.forEach(e => {
                    this.itemDeliveryLocations.push({
                        ...e,
                        location:e.deliveryLocation+' (Qty => '+e.item.quantity+' / Dlrd Qty => '+e.item.delivered_qty+')',
                    });
                });
                
                this.deptPo = res.data.deptPoNo;
                this.deptPoNo = res.data.deptPoNo.po_no;
                this.spinnerLoadingStatus = false;
                this.dcDialogVisibility = true;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            })
        },
        addItem(){
            this.selectedInvoiceItem.avalQty = this.selectedInvoiceItem.totalQty - this.selectedInvoiceItem.quantity;
            this.dcItems.push(this.selectedInvoiceItem);

            // this.dcItems.forEach(e => {
            //     this.totalDcQty += e.availableQty;
            // });
        },
        saveDeliveryChallan(){

            if(this.dcDept==null || this.dcDate==null || this.selectedDeliveryLocation==null || this.deptPoNo==null || this.dcItems.length==0){   
                return this.showErrorToast({'message':'Dept/Date/Location/Po No/Items may be missing!'});
            }
            
            const data = {
                'dept':this.dcDept,
                'dcDate':this.dcDate.getFullYear()+'-'+(this.dcDate.getMonth()+1)+'-'+this.dcDate.getDate(),
                'deliveryLocation':this.selectedDeliveryLocation.item.location_id,
                'deptPo':this.deptPo.uuid,
                // 'deptPoNo':this.deptPoNo,
                'dcItems':this.dcItems,
            }



            this.hideDcDialog();
            this.spinnerLoadingStatus = true;

            deliveryChallan(data).then((res)=>{
                this.showSuccessToast(res.data);
                this.spinnerLoadingStatus = false;
                this.$router.push('/delivery-challan');
                // this.getStockList();
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            })
        },
        updateAvailable(event, index){
            
            const item = this.dcItems[index];
            // this.totalDcQty = 0;
            
            item.avalQty = item.availableQty - event.value;
            // this.dcItems.forEach(e => {
            //     this.totalDcQty += e.availableQty;
            // });
        },
        deleteDcItems(index){
            // this.totalDcQty -= this.dcItems[index].availableQty;
            this.dcItems.splice(index, 1);
        },
        hideDcDialog(){
            this.dcDialogVisibility = false;
            this.dcDept = null;
            this.dcItems = [];
            this.selectedInvoiceItem = null;
            this.dcDate = null;
            this.selectedDeliveryLocation = null;
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        },
    },
    mounted(){
        this.getStockList();
    }

}
</script>

<style scoped>

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

</style>