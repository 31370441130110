<template>
<div><ProgressBar v-if="loading" mode="indeterminate" style="height: 6px"/></div>
<Toast/>
<!-- style="margin-top:5%; margin-bottom:5%;" -->
   <div class="surface-ground flex align-items-center justify-content-center overflow-hidden h-screen">
        <div class="flex flex-column align-items-center justify-content-center" >
            <div class="w-full" style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 100%)">
                <div class="w-full surface-card py-5 px-3 sm:px-8" style="border-radius: 53px">
                    <div class="text-center mb-5">
                        <img :src="logoUrl" alt="AMS logo" height="70" class="mb-3" />
                        <!-- <div class="text-3xl font-medium mb-3">Arengh Medical Supplier</div> -->
                        <!-- <h2>Arengh Medical Supplier</h2> -->
                        <div style="font-size:24px; font-weight:510; font-family: Georgia, serif;"> <em> Arengh Medical Supplier </em></div>
                        <span  style="font-size:14px;">Sign in to continue</span>
                    </div>

                    <div>
                        <label for="email1" class="block text-900 text-base font-medium mb-2">Email</label>
                        <InputText id="email1" type="text" placeholder="Email address" class="w-full mb-3" :inputStyle="{ padding: '1rem' }" v-model="email" required />
                        <InlineMessage v-if="emptyEmail">Email is required</InlineMessage>

                        <label for="password1" class="block text-900 font-medium text-base mb-2">Password</label>
                        <Password id="password1" v-model="password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" :inputStyle="{ padding: '1rem' }" required @keyup.enter="handleAuth"></Password>
                        <InlineMessage v-if="emptyPassword" >Password is required</InlineMessage>

                        <div class="flex align-items-center justify-content-between mb-5 gap-5">
                            <!-- <div class="flex align-items-center"> -->
                                <!-- <Checkbox v-model="checked" id="rememberme1" binary class="mr-2"></Checkbox> -->
                                <!-- <label for="rememberme1" class="text-sm">Remember me</label> -->
                            <!-- </div> -->
                            <!-- <a class="font-medium no-underline ml-2 text-right cursor-pointer text-sm" style="color: var(--primary-color)">Forgot password?</a> -->
                        </div>
                        <Button label="Sign In" class="w-full p-3 text-base" @click="handleAuth"></Button>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import Button from "primevue/button";
// import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import { ref } from 'vue';
import InlineMessage from 'primevue/inlinemessage';
import Toast from 'primevue/toast';
import { login } from '../../services/apiService'
import ProgressBar from 'primevue/progressbar';
import { useNotificationStore } from '@/stores/notificationStore';
// import { useAuthStore } from '@/stores/authStore';

export default {
    
    components:{ 
        Button, 
            InputText, 
            Password, 
            // Checkbox, 
            InlineMessage, 
            Toast, 
            ProgressBar
            },

data(){
    return{
        loading:false,
        email : ref(''),
        password : ref(''),
        checked : ref(false),
        logoUrl : '/assets/images/logo.png',
        emptyEmail : false,
        emptyPassword : false
    }  
},
methods:{
    
    async handleAuth(){
        if(this.email==""){
           return this.emptyEmail = true;
        }
        if(this.password==""){
           return this.emptyPassword = true;
        }
        try {
            this.emptyEmail = false,
            this.emptyPassword = false;
            this.loading = true;
            login(this.email, this.password)
            .then((res)=>{
                const notificationStore = useNotificationStore();
                localStorage.setItem('bearer-token', res.data.bearer_token);
                localStorage.setItem('user-token', res.data.user.uuid);                
                this.$router.push('dashboard'); 
                this.showToast(res.data);
                this.loading = false;  

                notificationStore.$patch((state)=>{
                    res.data.notifications.forEach(e => {
                        state.notifications.push({
                            message:{
                                data:e,
                            }
                        })
                    });
                }) 
                
            })
            .catch((error)=>{
                this.$router.push('/')
                this.errorToast(error.response.data);
                this.loading = false;
            });
        } catch (error) {
            this.errorToast(error.response.data)
        }
    },
    showToast(data){
        this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
    },
    errorToast(data){
        this.$toast.add({ severity: 'error', summary: 'Error', detail:data.message, life: 3000 });
    }
    
}

}
</script>

<style>

</style>